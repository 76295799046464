import cn from 'classnames';

import { Header } from '@webapp/common/components/header';
import { useToggle } from '@webapp/common/hooks/use-toggle';
import { ColorScheme } from '@webapp/common/lib/ui';
import { Arrow } from '@webapp/ui/lib/icons';
import { Menu } from '@webapp/ui/lib/menu';
import { PrivacyPopup } from '@webapp/ui/lib/privacy-popup';

import { Footer } from './footer';

import css from './layout.css';

export const LeftMenu: FC = () => {
    const { enabled: visible, toggle } = useToggle(false);

    return (
        <aside className={css.aside}>
            <div className={css.collapse} onClick={toggle}>
                <div className={css.link}>
                    <Arrow />
                    <span>Навигация</span>
                </div>
            </div>
            <div className={cn(css.menu, { [css.mobileVisible]: visible })}>
                <Menu />
            </div>
        </aside>
    );
};

export const Layout: FC<{
    className?: string;
    headerClassName?: string;
    mode?: ColorScheme;
    withMenu?: boolean;
    contentPage?: boolean;
    page?: 'main';
}> = ({ children, className, headerClassName, mode = ColorScheme.LIGHT, withMenu, contentPage, page }) => (
    <div className={cn(css.page, className)}>
        <Header className={headerClassName} logoLink='/' mode={mode} isLanding={true} page={page} />
        <div className={cn(css.content, withMenu && css.withMenu, contentPage && css.contentPage)}>
            {withMenu && <LeftMenu />}
            {children}
        </div>
        <Footer />
        <div id='popper' />
        <PrivacyPopup />
    </div>
);
