import type { ISuperAdminUser, User, UsersState } from 'resources/users/users';

import type { States } from '../states';

export const selectUsersState = ({ Users }: States): UsersState => Users;

export const selectUsers = (states: States): Array<User> => {
    const u = selectUsersState(states);

    return u.users;
};

export const selectSuperAdminUsers = (states: States): Array<ISuperAdminUser> => {
    const u = selectUsersState(states);

    return u.superAdminUsers;
};
