export const PROFILE_AUTH_FETCH = 'PROFILE_AUTH_FETCH';
export const PROFILE_AUTH_SUCCESS = 'PROFILE_AUTH_SUCCESS';
export const PROFILE_AUTH_FAIL = 'PROFILE_AUTH_FAIL';

export const PROFILE_GET_FETCH = 'PROFILE_GET_FETCH';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAIL = 'PROFILE_GET_FAIL';

export const DEVICE_CONFIRM_CODE_FETCH = 'DEVICE_CONFIRM_CODE_FETCH';

export const DEVICE_CONFIRM_FETCH = 'DEVICE_CONFIRM_FETCH';
export const DEVICE_CONFIRM_SUCCESS = 'DEVICE_CONFIRM_SUCCESS';

export const DEVICE_CONFIRM_HASH_FETCH = 'DEVICE_CONFIRM_HASH_FETCH';
export const DEVICE_CONFIRM_HASH_SUCCESS = 'DEVICE_CONFIRM_HASH_SUCCESS';
export const DEVICE_CONFIRM_HASH_ERROR = 'DEVICE_CONFIRM_HASH_ERROR';

export const QUOTAS_GET_FETCH = 'QUOTAS_GET_FETCH';
export const QUOTAS_GET_SUCCESS = 'QUOTAS_GET_SUCCESS';

export const PROFILE_REGISTER_FETCH = 'PROFILE_REGISTER_FETCH';
export const PROFILE_REGISTER_SUCCESS = 'PROFILE_REGISTER_SUCCESS';
export const PROFILE_REGISTER_FAIL = 'PROFILE_REGISTER_FAIL';

export const PROFILE_CONFIRM_EMAIL_FETCH = 'PROFILE_CONFIRM_FETCH';
export const PROFILE_CONFIRM_EMAIL_SUCCESS = 'PROFILE_CONFIRM_SUCCESS';
export const PROFILE_CONFIRM_EMAIL_FAIL = 'PROFILE_CONFIRM_FAIL';

export const PROFILE_RESEND_CONFIRM_EMAIL_FETCH = 'PROFILE_RESEND_CONFIRM_EMAIL_FETCH';
export const PROFILE_RESEND_CONFIRM_EMAIL_SUCCESS = 'PROFILE_RESEND_CONFIRM_EMAIL_SUCCESS';
export const PROFILE_RESEND_CONFIRM_EMAIL_FAIL = 'PROFILE_RESEND_CONFIRM_EMAIL_FAIL';

export const PROFILE_RESET_PASSWORD_FETCH = 'PROFILE_RESET_PASSWORD_FETCH';
export const PROFILE_RESET_PASSWORD_SUCCESS = 'PROFILE_RESET_PASSWORD_SUCCESS';
export const PROFILE_RESET_PASSWORD_FAIL = 'PROFILE_RESET_PASSWORD_FAIL';

export const PROFILE_CONFIRM_RESET_PASSWORD_FETCH = 'PROFILE_CONFIRM_RESET_PASSWORD_FETCH';
export const PROFILE_CONFIRM_RESET_PASSWORD_SUCCESS = 'PROFILE_CONFIRM_RESET_PASSWORD_SUCCESS';
export const PROFILE_CONFIRM_RESET_PASSWORD_FAIL = 'PROFILE_CONFIRM_RESET_PASSWORD_FAIL';

export const PROFILE_CHANGE_PASSWORD_FETCH = 'PROFILE_CHANGE_PASSWORD_FETCH';
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'PROFILE_CHANGE_PASSWORD_SUCCESS';
export const PROFILE_CHANGE_PASSWORD_FAIL = 'PROFILE_CHANGE_PASSWORD_FAIL';

export const PROFILE_LOGOUT = 'PROFILE_LOGOUT';

export const PROFILE_SAVE_FETCH = 'PROFILE_SAVE_FETCH';
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS';
export const PROFILE_SAVE_FAIL = 'PROFILE_SAVE_FAIL';

export const PROFILE_CHANGE_EMAIL_UNCONFIRMED_FETCH = 'PROFILE_CHANGE_EMAIL_UNCONFIRMED_FETCH';
export const PROFILE_CHANGE_EMAIL_UNCONFIRMED_FAIL = 'PROFILE_CHANGE_EMAIL_UNCONFIRMED_FAIL';
export const PROFILE_CHANGE_EMAIL_UNCONFIRMED_SUCCESS = 'PROFILE_CHANGE_EMAIL_UNCONFIRMED_SUCCESS';

// use licence for additional user
export const PROFILE_USE_USER_LICENCE = 'PROFILE_USE_USER_LICENCE';
