import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class DistributeScaleApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({
                answer: id,
                int: typeof value === 'number' ? value : 0,
                bool: true
            })),
            comment: commentText
        };
    }

    public validate({
        answers,
        params: { distributeScore, distributeSurplus, required }
    }: ISurveyQuestionModel): boolean {
        if (required) {
            const sum = answers.reduce((acc, { response: { value } }) => acc + (value as number), 0);
            return (
                (distributeSurplus && sum === parseInt(distributeScore.toString())) || (!distributeSurplus && sum > 0)
            );
        }

        return true;
    }
}
