import { Button } from 'components/ui/button';
import AnswersIntegrationIcon from 'components/ui/icons/answers-integration.svg';
import AutoSendIcon from 'components/ui/icons/auto-send.svg';
import GetAnswersIcon from 'components/ui/icons/get-answers.svg';
import ScriptSettingsIcon from 'components/ui/icons/scripts-settings.svg';
import ViewCustomizationIcon from 'components/ui/icons/view-customization.svg';

import css from './api.css';

const items = [
    {
        title: 'Интеграция ответов',
        icon: <AnswersIntegrationIcon />,
        descr: 'Интеграция ответов респондентов в вашу внутреннюю систему или CRM'
    },
    {
        title: 'Настройки сценариев',
        icon: <ScriptSettingsIcon />,
        descr: 'Настройки сценариев показа опроса на сайте в зависимости от действий клиента'
    },
    {
        title: 'Автоматизированная отправка',
        icon: <AutoSendIcon />,
        descr: 'Автоматизированная отправка опроса на email клиенту после совершения покупки'
    },
    {
        title: 'Кастомизация отображения',
        icon: <ViewCustomizationIcon />,
        descr: 'Кастомизация отображения результатов опроса'
    },
    {
        title: 'Получение отчетов',
        icon: <GetAnswersIcon />,
        descr: 'Получение отчетов для выбранных опросов за период'
    }
];

export const API: FC = () => (
    <div className={css.api}>
        <h1 className={css.subheader}>
            <div className={css.subheaderText}>API</div>
            <Button href='/ru/docs-private-api'>Разработчикам</Button>
        </h1>
        <div className={css.items}>
            {items.map(({ descr, icon, title }) => (
                <section className={css.item} key={title}>
                    {icon}
                    <h4>{title}</h4>
                    <p>{descr}</p>
                </section>
            ))}
        </div>
    </div>
);
