import type { AnswersRequest } from '@webapp/common/resources/mst-survey/api/queries-api';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import { QuestionSimpleApi } from './simple';

export class VoidQuestionApi extends QuestionSimpleApi {
    query({ id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: [],
            comment: ''
        };
    }

    public validate(_: ISurveyQuestionModel): boolean {
        return true;
    }
}
