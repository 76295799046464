import cn from 'classnames';
import { useRouter } from 'next/router';

import type { Link } from './data';
import { checkIsActive, getPathname } from './lib';

import css from './menu.css';

export const MenuItem: FC<Link> = ({ links, name, path }) => {
    const nextRouter = useRouter();
    const location = typeof window !== 'undefined' && window.location;
    const pathname = nextRouter ? nextRouter.asPath : location.pathname;

    let slug = pathname.split('?')[0];
    slug = slug.split('/').pop();
    const isActive = checkIsActive({ name, path, links }, slug);

    if (!isActive || !links) {
        const href = `/ru/${getPathname({ path, links, name })}`;

        return (
            <li className={cn(css.item, { [css.active]: isActive })}>
                <a className={css.link} href={href}>
                    {name}
                </a>
            </li>
        );
    }

    const link = links[0].path || links[0].links[0].path;

    return (
        <div className={cn(css.item, { [css.active]: isActive })}>
            <a className={css.action} href={link}>
                {name}
            </a>
            <div className={cn(css.sub, { [css.visible]: isActive })}>
                <MenuItems links={links} />
            </div>
        </div>
    );
};

export const MenuItems: FC<{
    links: Array<Link>;
}> = ({ links }) => (
    <ul className={css.links}>
        {links.map((link) => (
            <MenuItem key={link.name} {...link} />
        ))}
    </ul>
);
