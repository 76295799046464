import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import { CANTRATE_ID } from '../constants';
import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class NpsApi extends QuestionSimpleApi {
    public query({ commentText: comment, id: question, response }: ISurveyQuestionModel): AnswersRequest {
        const int = response.value as number;
        const data = int === CANTRATE_ID ? { cantRate: true } : { int };

        return {
            question,
            data: [{ bool: true, comment, ...data }],
            comment
        };
    }

    public validate({ params: { required }, response: { value } }: ISurveyQuestionModel): boolean {
        if (required) {
            return value !== undefined && value !== null;
        }

        return true;
    }
}
