import { useCallback, useState } from 'react';

import { getEulaAcceptedCookie, setEulaAcceptedCookie } from '@webapp/common/lib/cookies';
import { isEmbedded } from '@webapp/common/lib/ua';
import { Button } from '@webapp/ui/lib/button';

import css from './privacy-popup.css';

const show = !getEulaAcceptedCookie();

export const PrivacyPopup: FC = () => {
    const [visible, setVisible] = useState(show && !isEmbedded);

    const accept = useCallback(() => {
        setVisible(false);
        setEulaAcceptedCookie();
    }, []);

    if (!visible) return null;

    return (
        <div className={css.privacyPopup}>
            <div className={css.content}>
                <span>
                    Продолжая пользование настоящим сайтом, Вы выражаете своё согласие на обработку Сookie-файлов в
                    соответствии с{' '}
                    <a href='https://www.testograf.ru/ru/docs-cookie' rel='noreferrer' target='_blank'>
                        Политикой использования Cookie-файлов
                    </a>
                </span>
            </div>
            <Button slim color='blue' onClick={accept}>
                Принять
            </Button>
        </div>
    );
};
