import css from './safety.css';

export const Safety: FC = () => (
    <div className={css.safety}>
        <h2>Преимущества</h2>
        <p className={css.name}>Стек</p>
        <p>
            Сервис написан на современном стеке технологий (react, php, kubernetes). Это позволяет нам быстро
            масштабировать сервис, регулярно выпускать обновления функционала, держать высокие нагрузки и обеспечивать
            годовую доступность сервиса в 99,99%.
        </p>
        <p className={css.name}>Безопасность</p>
        <p>
            Процессы ИБ выстраиваются в соответствии с циклом PDCA. В частности, в рамках планирования системы защиты
            информации разработаны внутренние нормативные документы по вопросам обработки и защиты ПДн, а также в сфере
            ИБ. Внедряются и реализуются организационные и технические меры защиты информации: шифрование данных, защита
            от DDos и хакерских атак, ежедневное резервное копирование данных, двойная аутентификация пользователей,
            организация внутреннего контроля ИТ- и ИБ-процессов.
        </p>
        <p className={css.name}>Отказоустойчивость</p>
        <p>
            Обязательными критериями при выборе дата-центра являются: непрерывность электропитания, контроль
            микроклимата в серверных помещениях, строгая система доступа и круглосуточный контроль работы ключевых
            систем системными инженерами. Но и этого нам показалось мало, поэтому мы размещаемся в двух независимых друг
            от друга дата-центрах.
        </p>
        <p className={css.name}>Обновления функционала</p>
        <p>
            Обновления функционала происходят с использованием технологии Continuous integration и Continuous
            deployment, что обеспечивает автоматическое тестирование нового функционала перед запуском в работу. Мы
            также используем Blue green deployment для быстрого отката в случае непредвиденных сбоев при обновлении
            функционала, при этом откат производится без простоя в работе сервиса.
        </p>
        <p className={css.name}>Сделано в России</p>
        <p>
            Мы предоставляем лицензионное ПО (номер свидетельства 2019618230), входящее в Реестр российского ПО (номер
            записи 13533), располагаем серверы на территории РФ и официально зарегистрированы в Роскомнадзоре как
            оператор ПДн (регистрационный номер 77-17-007222).
        </p>
        <p className={css.name}>Поддержка</p>
        <p>
            Мы стараемся сделать так, чтобы поддержка вам не понадобилась. Но если возникли вопросы, мы доступны по
            email и телефону. Нашу поддержку любят, нашу поддержку хвалят.
        </p>
    </div>
);
