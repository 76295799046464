import dayjs from 'dayjs';

import type { User } from './users';

interface UserResp {
    ID: number;
    LOGIN: string;
    NAME: string;
    FULL_NAME: string;
    PARENT_ID: number;
    STATUS: number;
    VALID_DATE: string;
    ROLE: number;
}

export const mapUser = ({ ID, LOGIN, NAME, ROLE, STATUS, VALID_DATE, FULL_NAME }: UserResp): User => ({
    id: ID,
    name: NAME,
    fullName: FULL_NAME,
    email: LOGIN,
    status: STATUS,
    role: ROLE,
    validDate: dayjs(VALID_DATE)
});
