import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class MatrixRatingApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id: answerId, response: { value: numOfStars } }) => ({
                answer: answerId,
                bool: true,
                int: numOfStars as number
            })),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            answers.forEach((a) => a.setValid(!!a.response.value));

            return answers.every(({ response: { value } }) => !!value);
        }

        return true;
    }
}
