import cloneDeep from 'lodash/cloneDeep';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { PLACEHOLDER } from 'resources/questions/fillers';

import { CheckMark, Infinity, RedCross, Search } from '@webapp/ui/lib/icons';
import { Switch } from '@webapp/ui/lib/switch';

import { MAX, sections, types } from './data';

import css from './functions.css';

export const Functions: FC = () => {
    const [showDiff, setShowDiff] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [sectionsFiltered, setSectionsFiltered] = useState(sections);

    const filterSections = useCallback((search: string, diff: boolean) => {
        const found = search
            ? sections.reduce((acc, curr) => {
                  const res = cloneDeep(curr);
                  res.items = Object.entries(res.items).reduce((items, [k, v]) => {
                      if (v.toLowerCase().includes(search)) {
                          items[k] = v;
                      }
                      return items;
                  }, {});

                  const titleMatch = curr.title.toLowerCase().includes(search);
                  const itemsMatch = Object.keys(res.items).length > 0;

                  if (titleMatch || itemsMatch) {
                      acc.push(res);
                  }

                  return acc;
              }, [])
            : sections;

        return diff
            ? found.reduce((acc, curr) => {
                  const res = cloneDeep(curr);

                  res.items = Object.entries(res.items).reduce((items, [k, v]) => {
                      if (types[0][k] !== types[1][k] || types[1][k] !== types[2][k] || types[0][k] !== types[2][k]) {
                          items[k] = v;
                      }
                      return items;
                  }, {});

                  if (Object.keys(res.items).length > 0) {
                      acc.push(res);
                  }

                  return acc;
              }, [])
            : found;
    }, []);

    const onSearch = useCallback(({ currentTarget: { value } }) => {
        // TODO add debounce
        setSearch(value.trim().toLowerCase());
    }, []);

    useEffect(() => {
        setSectionsFiltered(filterSections(search, showDiff));
    }, [filterSections, search, showDiff]);

    return (
        <div className={css.functions}>
            <h1>Функционал</h1>
            <div className={css.search}>
                <Search />
                <input defaultValue='' placeholder={PLACEHOLDER.SEARCH} onChange={onSearch} />
            </div>
            <div className={css.tableHeader}>
                <Switch
                    className={css.diffSwitch}
                    defaultChecked={showDiff}
                    label='Показать отличия'
                    onChange={setShowDiff}
                />
                {types.map(({ title }) => (
                    <>{title}</>
                ))}
            </div>
            <div className={css.table}>
                {sectionsFiltered.map(({ items, title }) => (
                    <Fragment key={title}>
                        <div className={css.tableGroupHeader}>{title}</div>
                        {Object.entries(items).map(([k, v]) => (
                            <div className={css.row} key={v}>
                                <div>{v}</div>
                                {types.map((t, idx) => {
                                    const val = t[k];
                                    let content = val;
                                    let className = css.blue;

                                    if (val === MAX) {
                                        content = <Infinity />;
                                    } else if (typeof val === 'boolean') {
                                        content = val ? <CheckMark /> : <RedCross />;
                                        className = val ? css.green : css.red;
                                    } else if (typeof val === 'string') {
                                        content = val;
                                    } else {
                                        className = css.green;
                                        content = <CheckMark />;
                                    }

                                    return (
                                        <div className={className} key={idx}>
                                            {content}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </Fragment>
                ))}
            </div>
            <h3 className={css.bottomLink}>
                <a href='/ru/blog/manual'>Установка и эксплуатация Testograf</a>
            </h3>
        </div>
    );
};
