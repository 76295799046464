import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class NameApi extends QuestionSimpleApi {
    public query({ commentText, groups, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: groups.flatMap(({ id: groupId, responses }) => {
                const resps = responses.toJSON();
                return Object.keys(resps).map((key) => ({
                    group: groupId,
                    answer: parseInt(key),
                    bool: true,
                    text: String(resps[key]?.response?.value)
                }));
            }),
            comment: commentText
        };
    }

    public validate({ answers, groups, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            const rowCount = answers.length; // TODO check this actually works

            return groups.every((group) => {
                const responseIds = [...group.responses.keys()];

                if (responseIds.length < rowCount) return false;

                return responseIds.length > 0 && responseIds.every((key) => !!group.responses.get(key).response.value);
            });
        }

        return true;
    }
}
