import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class RankApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({ answer: id, int: Number(value) + 1, bool: true })),
            comment: commentText
        };
    }

    public validate({ params: { required }, wasChanged }: ISurveyQuestionModel): boolean {
        if (!required) {
            return true;
        }
        return wasChanged;
    }
}
