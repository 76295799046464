import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class ShortTextApi extends QuestionSimpleApi {
    public query({ commentText, id, response }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: [{ text: response.value as string, bool: true, comment: commentText }],
            comment: commentText
        };
    }
}
