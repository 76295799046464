import { AccountRoutes } from '@webapp/account/src/resources/routes';

import { Button } from 'components/ui/button';

import css from './about.css';

export const About: FC = () => (
    <div className={css.about}>
        <h2>О сервисе</h2>
        <p>Сервис testograf был запущен в 2014 году.</p>
        <p>
            Основное направление – разработка и совершенствование собственного решения для проведения онлайн опросов и
            тестов.
        </p>
        <p>
            В 2022 году мы выпустили новую версию на современном стеке технологий (react, php, kubernetes). Сегодня это
            позволяет нам быстро масштабировать сервис, регулярно выпускать обновления функционала, держать высокие (и
            очень высокие) нагрузки и обеспечивать годовую доступность сервиса в 99,99%.
        </p>
        <p>
            Вы доверяете нам свои данные, поэтому мы уделяем большое внимание безопасности сервиса. Внедряются и
            реализуются организационные и технические меры защиты информации: шифрование данных, защита от DDos и
            хакерских атак, резервное копирование данных, а серверы размещаются в двух независимых дата-центрах.
        </p>
        <p>
            Наша работа заключается в поиске и реализации наиболее эффективных и удобных функциональных решений,
            позволяющих сделать testograf лучшим профессиональным конструктором опросов.
        </p>
        <Button href={AccountRoutes.authRegister.link}>Подключиться</Button>
    </div>
);
