import type { Link } from './data';

export const checkIsActive = ({ links, path }: Link, id: string): boolean => {
    if (path === id) {
        return true;
    }
    if (Array.isArray(links) && links.length !== 0) {
        return links.some((link) => checkIsActive(link, id));
    }
};
export const getPathname = ({ links, path }: Link): string => {
    if (!Array.isArray(links)) {
        return path;
    }
    return getPathname(links[0]);
};
