import isFinite from 'lodash/isFinite';

import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class NumberApi extends QuestionSimpleApi {
    public query({ commentText, id, response: { value } }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: [{ int: parseFloat(String(value)), bool: true, comment: commentText }],
            comment: commentText
        };
    }

    public validate({ params: { maxLength, minLength, required }, response }: ISurveyQuestionModel): boolean {
        const value = parseInt(`${response.value}`);

        if (!required) return true;

        if (minLength !== null && value < minLength) {
            return false;
        }

        if (maxLength !== null && value > maxLength) {
            return false;
        }

        return isFinite(value);
    }
}
