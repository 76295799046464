import Link from 'next/dist/client/link';

import { Button as CommonButton } from '@webapp/ui/lib/button';

import css from './button.css';

export const Button: FC<{
    href?: string;
    onClick?: ReactMouseEventHandler;
}> = ({ children, href, onClick }) => (
    <CommonButton className={css.button} color='blue' onClick={onClick}>
        {href ? <Link href={href}>{children}</Link> : children}
    </CommonButton>
);
