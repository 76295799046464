import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class ResidenceApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { extra, value } }, idx) => ({
                bool: true,
                text: value ? String(value) : null,
                longtext: extra ? String(extra) : null,
                int: idx + 1,
                answer: id
            })),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (!required) {
            return true;
        }

        return answers.every(({ response: { value } }) => !!value);
    }
}
