import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { ListApi } from './common/list';

export class OneOfListApi extends ListApi {
    public change(
        { answers }: ISurveyQuestionModel,
        { answer, setValue }: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        setValue(value);

        if (value) {
            answers.forEach((otherAnswer) => {
                if (answer !== otherAnswer) {
                    otherAnswer.response.setValue(false);

                    if (otherAnswer.response.extra) {
                        otherAnswer.response.setExtra('');
                    }
                }
                if (!otherAnswer.valid) {
                    otherAnswer.setValid(true);
                }
            });
        }
    }

    public query({ answers, commentText, id, response }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { extra, value } }) => {
                value = id === response.value || Boolean(value);
                return {
                    answer: id,
                    bool: value,
                    longtext: value && extra !== null ? String(extra) : undefined
                };
            }),
            comment: commentText
        };
    }

    public validate = ({ answers, params: { required } }: ISurveyQuestionModel): boolean => {
        if (!required) return true;

        answers.forEach(({ response: { value }, setValid }) => {
            if (!value) {
                setValid(false);
            }
        });

        return answers.some(
            ({ response: { comment, extra, value }, type }) => !!value && (type === null || comment || extra)
        );
    };
}
