import Notification from 'rc-notification';

import './message.css?CSSModulesDisable';

import { CheckCircle, CloseCircle, InfoCircle, Loading } from '../icons';

const prefixCls = 'tt-message';

let messageInstance: any;
let incId = 0;

enum AlertType {
    LOADING = 'loading',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success'
}

const iconMap = {
    info: InfoCircle,
    success: CheckCircle,
    error: CloseCircle,
    loading: Loading
};

const getMessageInstance = (callback: (i: any) => void): void => {
    if (messageInstance) {
        return callback(messageInstance);
    }

    Notification.newInstance(
        {
            prefixCls,
            transitionName: 'move-up',
            style: { top: 0 },
            getContainer: (): HTMLElement => document.getElementById('notify'),
            maxCount: 2
        },
        (instance: any) => {
            if (messageInstance) {
                return callback(messageInstance);
            }
            messageInstance = instance;
            callback(instance);
        }
    );
};

const notice = (type: AlertType, msg: string, key?: string | number): void => {
    if (!msg) {
        return console.warn('empty alert');
    }

    if (typeof msg !== 'string') {
        msg = String(msg);
    }

    const IconComponent = iconMap[type];
    key = key || ++incId;

    getMessageInstance((instance) =>
        instance.notice({
            key,
            duration: 3,
            style: {},
            content: (
                <div className={`${prefixCls}-custom-content${type ? ` ${prefixCls}-${type}` : ''}`}>
                    {IconComponent && (
                        <span className={'tt-icon'}>
                            <IconComponent />
                        </span>
                    )}
                    <span>{msg}</span>.
                </div>
            )
        })
    );
};

type AlertCall = (msg?: string, key?: string | number) => void;

const info: AlertCall = (msg, key): void => notice(AlertType.INFO, msg, key);
const success: AlertCall = (msg = 'Сохранено', key): void => notice(AlertType.SUCCESS, msg, key);
const error: AlertCall = (msg = 'Ошибка', key): void => notice(AlertType.ERROR, msg, key);
const saving: AlertCall = (msg = 'Сохранение...', key): void => notice(AlertType.LOADING, msg, key);

const destroy = (): void => {
    messageInstance?.destroy();
    messageInstance = null;
};

const Alert = { info, success, error, saving, destroy };

export { Alert };
