import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class SelectFewImageApi extends QuestionSimpleApi {
    public change(
        { answers }: ISurveyQuestionModel,
        { answer, setValue }: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        setValue(value);
        answers.forEach((otherAnswer) => {
            if ((answer.exception || otherAnswer.exception) && answer !== otherAnswer) {
                otherAnswer.response.setValue(false);
            }
        });
    }

    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({ answer: id, bool: (value as boolean) || false })),
            comment: commentText
        };
    }

    public validate({
        answers,
        params: { maxLength, minLength, required },
        setAnsweredCount,
        setInvalid
    }: ISurveyQuestionModel): boolean {
        const answersCount = answers.reduce((acc, { response: { value } }) => acc + Number(value), 0);

        if (!required) return true;

        setAnsweredCount(answersCount);

        const withMinLen = minLength !== null;
        const withMaxLen = maxLength !== null;

        if ((withMinLen && answersCount < minLength) || (withMaxLen && maxLength < answersCount)) {
            setInvalid(true);
            return false;
        }

        return answersCount > 0;
    }
}
