import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import { CANTRATE_ID } from '../constants';
import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class ScaleApi extends QuestionSimpleApi {
    public query({ answers, commentText: comment, id: question, response }: ISurveyQuestionModel): AnswersRequest {
        const group = response.value as number;

        const data =
            group === CANTRATE_ID
                ? { cantRate: true }
                : {
                      answer: answers[0].id,
                      group
                  };

        return {
            question,
            data: [{ bool: true, ...data }],
            comment
        };
    }
}
