import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class DropdownListApi extends QuestionSimpleApi {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        response.setValue(value);

        if (question.answers.length > 0) {
            question.answers.forEach((a) => {
                a.response.setValue(a.id === value ? value : null);
            });
        }
    }

    public query({ answers, commentText, id, response }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers
                .filter((a) => a.id === response.value)
                .map(({ id, value: [value1] }) => ({ answer: id, bool: true, text: value1 as string })),
            comment: commentText
        };
    }
}
