import { useCallback, useMemo, useState } from 'react';

export const useToggle = (
    initial = false
): {
    enabled: boolean;
    on: () => void;
    off: () => void;
    toggle: () => void;
} => {
    const [enabled, setEnabled] = useState(initial);

    const toggle = useCallback(() => setEnabled((v) => !v), []);
    const off = useCallback(() => setEnabled(false), []);
    const on = useCallback(() => setEnabled(true), []);

    return useMemo(
        () => ({
            enabled,
            on,
            off,
            toggle
        }),
        [enabled, off, on, toggle]
    );
};
