import type { AnswersRequest, QuestionApi } from '@webapp/common/resources/mst-survey/api/queries-api';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

export abstract class ListApi implements QuestionApi {
    public abstract change(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void;

    public changeExtra(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setExtra(value);

        if (value) {
            void response.change(!!value);
        }
    }

    public abstract query(q: ISurveyQuestionModel): AnswersRequest;

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            answers.forEach(({ response }) => !!response);
        }

        return true;
    }
}
