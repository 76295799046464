import { BoolAnswerText } from '@webapp/common/lib/ui';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class AgreementApi extends QuestionSimpleApi {
    public query({ answers, commentText, id, response }: ISurveyQuestionModel): AnswersRequest {
        const checked = Boolean(response.value);
        const responseId = (() => {
            if (checked) {
                return answers.find(({ value: [label] }) => label === BoolAnswerText.TRUE).id;
            }

            return answers.find(({ value: [label] }) => label === BoolAnswerText.FALSE).id;
        })();

        return {
            question: id,
            data: [
                {
                    bool: checked,
                    answer: responseId,
                    comment: commentText
                }
            ],
            comment: commentText
        };
    }
}
