import type { AnyAction, AsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { isAnyOf } from '@reduxjs/toolkit';
import { call, put, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import type { States } from 'resources/states';

import { Alert } from '@webapp/ui/lib/message';

export const safeSagaWrapper = (
    saga: (action: AnyAction) => Generator<any>,
    failAction?: string
): ((action: AnyAction) => Generator<any>) =>
    function* (action: AnyAction) {
        try {
            yield saga(action);
        } catch ({ code, message }) {
            yield call(console.error, message);
            if (failAction) yield put({ type: failAction, message, code });
            yield call(Alert.error, message);
        }
    };

export function* takeLatestSafe(
    actionType: string,
    saga: (action: AnyAction) => Generator<any>,
    failAction?: string
): Generator<any> {
    function* takeLatestSafeSaga(): Generator<any> {
        const wrappedSaga = safeSagaWrapper(saga, failAction);
        yield takeLatest(actionType, wrappedSaga);
    }

    yield spawn(takeLatestSafeSaga);
}

export function* takeEverySafe(
    actionType: string,
    saga: (action: AnyAction) => Generator<any>,
    failAction?: string
): Generator<any> {
    function* takeEverySafeSaga(): Generator<any> {
        const wrappedSaga = safeSagaWrapper(saga, failAction);
        yield takeEvery(actionType, wrappedSaga);
    }

    yield spawn(takeEverySafeSaga);
}

export const wrapApiCall =
    <Args, Returned = AnyObject, ThunkApiConfig = unknown>(
        payloadCreator: AsyncThunkPayloadCreator<
            Returned & { message?: string },
            Args,
            ThunkApiConfig & { state: States }
        >,
        { notify = true, notifyError = true }: { notify?: boolean | string; notifyError?: boolean | string } = {}
    ) =>
    async (args, thunkAPI) => {
        const { requestId } = thunkAPI;
        notify && Alert.saving(undefined, requestId);
        try {
            const resp: any = await payloadCreator(args, thunkAPI);
            if (notify) {
                const message = typeof notify === 'string' ? notify : resp.message;
                Alert.success(message, requestId);
            }
            return resp;
        } catch ({ code, message, status }) {
            if (notifyError) {
                message = typeof notifyError === 'string' ? notifyError : message;
                console.error(message);
                Alert.error(message, requestId);
            }
            return thunkAPI.rejectWithValue({ code, message, status });
        }
    };

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

export const isPendingAction = (action: AnyAction): action is PendingAction => action.type.endsWith('/pending');
export const isRejectedAction = (action: AnyAction): action is RejectedAction => action.type.endsWith('/rejected');
export const isFulfillAction = (action: AnyAction): action is FulfilledAction => action.type.endsWith('/fulfilled');

export const addStateFetch = (builder, key = 'fetch'): typeof builder =>
    builder
        .addMatcher(isPendingAction, (s) => {
            s.fetch = true;
        })
        .addMatcher(isAnyOf(isFulfillAction, isRejectedAction), (s, _) => {
            s[key] = false;
        });
