import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import { CANTRATE_ID } from '../constants';
import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class StarApi extends QuestionSimpleApi {
    public query({ commentText: comment, id: question, response }: ISurveyQuestionModel): AnswersRequest {
        const int = response.value as number;
        const data = [int === CANTRATE_ID ? { bool: true, cantRate: true } : { bool: int > 0, int, comment }];

        return {
            question,
            data,
            comment
        };
    }
}
