import { SuccessMark } from '@webapp/ui/lib/icons';

import css from 'components/ru/connection-steps/connection-steps.css';
import { DownloadButton } from 'components/ui/download-button';

export const License: FC = () => (
    <div className={css.connectionSteps}>
        <h1>Шаблон договора</h1>
        <h2>Оферта, договоры</h2>
        <DownloadButton file='Лицензионный договор (шаблон).doc' />
        <p>
            Заполните реквизиты самостоятельно или вышлите карточку компании на info@testograf.ru. Также понадобится
            информация о выбранной лицензии, количестве пользователей и формате работы (в отдельных или общем Личном
            кабинете).
        </p>
        <p>Останется:</p>
        <ul className={css.steps}>
            <li className={css.step}>
                <SuccessMark />
                <p>Выставить счет (самостоятельно в ЛК или нашей командой)</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Оплатить счет</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Проверить срок действия лицензии в ЛК (доступ будет открыт в момент поступления оплаты)</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Приступить к работе</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Если есть вопросы - мы на info@testograf.ru</p>
            </li>
        </ul>
    </div>
);
