import SurveyIcon from 'components/ui/icons/survey.svg';
import WorkerIcon from 'components/ui/icons/worker.svg';

export const data = [
    {
        title: 'Примеры анкет для сотрудников',
        icon: WorkerIcon,
        sections: [
            {
                title: 'Трудоустройство',
                items: [
                    {
                        title: 'Удовлетворенность процедурой приема на работу',
                        href: 'https://www.testograf.ru/ru/blog/satisfaction-hiring-process-template'
                    },
                    {
                        title: 'Шаблон опроса по онбордингу / адаптации персонала',
                        href: 'https://www.testograf.ru/ru/blog/onboarding-survey-template'
                    }
                ]
            },
            {
                title: 'Лояльность/Вовлеченность/Мотивация',
                items: [
                    {
                        title: 'Отношение к компании (опрос Липпонена)',
                        href: 'http://testograf.ru/ru/blog/lipponen-survey-template'
                    },
                    {
                        title: 'Уровень мотивации сотрудников',
                        href: 'http://testograf.ru/ru/blog/employee-motivation-level-template'
                    },
                    {
                        title: 'Тест на вовлеченность персонала (Gallup Q12)',
                        href: 'http://testograf.ru/ru/blog/gallup-q12-survey-template'
                    },
                    {
                        title: 'Вовлеченность сотрудников',
                        href: 'http://testograf.ru/ru/blog/employee-engagement-template'
                    },
                    {
                        title: 'Индекс лояльности сотрудников eNPS',
                        href: 'https://www.testograf.ru/ru/blog/enps-survey-template'
                    },
                    {
                        title: 'Тайм менеджмент',
                        href: 'https://www.testograf.ru/ru/blog/time-management-survey-template'
                    }
                ]
            },
            {
                title: 'Оценка / самооценка',
                items: [
                    {
                        title: 'Круговая оценка работы персонала (Метод «360 градусов»)',
                        href: 'https://www.testograf.ru/ru/blog/survey-360-template'
                    },
                    {
                        title: 'Круговая оценка персонала "360 градусов" по компетенциям',
                        href: 'https://www.testograf.ru/ru/blog/360-degree-survey-template'
                    },
                    {
                        title: 'Самооценка сотрудника',
                        href: 'https://www.testograf.ru/ru/blog/employee-self-assessment-template'
                    },
                    {
                        title: 'Карьерные ожидания сотрудников',
                        href: 'https://www.testograf.ru/ru/blog/career-expectations-of-employees-survey-template'
                    },
                    {
                        title: 'Диагностики синдрома выгорания',
                        href: 'https://www.testograf.ru/ru/blog/burnout-syndrome-test-template'
                    },
                    {
                        title: 'Оценка руководителя (опрос Google)',
                        href: 'https://www.testograf.ru/ru/blog/manager-assessment-survey-template'
                    }
                ]
            },
            {
                title: 'Удовлетворенность',
                items: [
                    {
                        title: 'Анкета удовлетворенности сотрудников',
                        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-survey-template'
                    },
                    {
                        title: 'Оценка удовлетворенности сотрудников (опрос Спектора)',
                        href: 'https://www.testograf.ru/ru/blog/spector-survey-template'
                    },
                    {
                        title: 'Миннесотский опрос: Удовлетворенность сотрудников',
                        href: 'https://www.testograf.ru/ru/blog/minnesota-survey-employee-satisfaction-template'
                    },
                    {
                        title: 'Удовлетворенность работой и вознаграждениями',
                        href: 'https://www.testograf.ru/ru/blog/job-satisfaction-and-rewards-survey-template'
                    },
                    {
                        title: 'Удовлетворенность условиями труда с оценкой важности',
                        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-pro-survey-template'
                    }
                ]
            },
            {
                title: 'Уходящий сотрудник',
                items: [
                    {
                        title: 'Опрос уходящего сотрудника',
                        href: 'https://www.testograf.ru/ru/blog/exit-interview-template'
                    }
                ]
            },

            {
                title: 'Прочее',
                items: [
                    {
                        title: 'Корпоративная культура',
                        href: 'https://www.testograf.ru/ru/blog/corporate-culture-survey-template'
                    },
                    {
                        title: 'Диагностика аврала',
                        href: 'https://www.testograf.ru/ru/blog/diagnosis-of-emergency-work-template'
                    }
                ]
            }
        ]
    },
    {
        title: 'Тесты',
        icon: SurveyIcon,
        sections: [
            {
                title: null,
                items: [
                    {
                        title: 'Тест: Контент и email-маркетинг',
                        href: 'https://www.testograf.ru/ru/blog/content-email-marketing-test-template'
                    },
                    {
                        title: 'Тестирование: Английский язык, уровень Advanced',
                        href: 'https://www.testograf.ru/ru/blog/advanced-english-test-template'
                    },
                    {
                        title: 'Тестирование: Английский язык, уровень Intermediate',
                        href: 'https://www.testograf.ru/ru/blog/intermediate-english-test-template'
                    },
                    {
                        title: 'Тест: Заполнение СЗВ-ТД',
                        href: 'https://www.testograf.ru/ru/blog/szv-td-test-template'
                    },
                    {
                        title: 'Тест: Отпуска и отпускные',
                        href: 'https://www.testograf.ru/ru/blog/vacation-test-template'
                    },
                    {
                        title: 'Тест по JavaScript для Middle-разработчика',
                        href: 'https://www.testograf.ru/ru/blog/middle-javascript-developer-test-template'
                    }
                ]
            }
        ]
    },
    {
        title: 'Опросы клиентов',
        icon: SurveyIcon,
        sections: [
            {
                title: null,
                items: [
                    {
                        title: 'Индекс лояльности (NPS)',
                        href: 'https://www.testograf.ru/ru/blog/nps-template'
                    },
                    {
                        title: 'Индекс удовлетворённости CSI',
                        href: 'https://www.testograf.ru/ru/blog/csi-survey-template'
                    },
                    {
                        title: 'Анкета тайного покупателя',
                        href: 'https://www.testograf.ru/ru/blog/mystery-shopper-survey-template'
                    },
                    {
                        title: 'Оценка лояльности клиента',
                        href: 'https://www.testograf.ru/ru/blog/customer-loyalty-assessment-template'
                    },
                    {
                        title: 'Опрос клиентов о качестве обслуживания',
                        href: 'https://www.testograf.ru/ru/blog/customer-service-quality-survey-template'
                    },
                    {
                        title: 'Узнаваемость бренда',
                        href: 'https://www.testograf.ru/ru/blog/brand-recognition-template'
                    },
                    {
                        title: 'Опрос по узнаваемости бренда №2',
                        href: 'https://www.testograf.ru/ru/blog/brand-awareness-template'
                    },
                    {
                        title: 'Анкета постоянного покупателя',
                        href: 'https://www.testograf.ru/ru/blog/survey-regular-customer-template'
                    },
                    {
                        title: 'Форма обратной связи для сайта',
                        href: 'https://www.testograf.ru/ru/blog/feedback-form-template'
                    }
                ]
            }
        ]
    },
    {
        title: 'Голосования',
        icon: SurveyIcon,
        sections: [
            {
                title: null,
                items: [
                    {
                        title: 'Оценка изображения',
                        href: 'https://www.testograf.ru/ru/blog/image-rating-vote-template'
                    },
                    {
                        title: 'Выбор лучшего изображения',
                        href: 'https://www.testograf.ru/ru/blog/best-image-vote-template'
                    },
                    {
                        title: 'Выбор лучшего',
                        href: 'https://www.testograf.ru/ru/blog/best-choice-vote-template'
                    },
                    {
                        title: 'Анкета обратной связи после мероприятия',
                        href: 'https://www.testograf.ru/ru/blog/event-feedback-template'
                    },
                    {
                        title: 'Обратная связь после корпоративного мероприятия',
                        href: 'https://www.testograf.ru/ru/blog/corporate-event-survey-template'
                    },
                    {
                        title: 'Оценка спикера на конференции',
                        href: 'https://www.testograf.ru/ru/blog/speaker-evaluation-vote-template'
                    },
                    {
                        title: 'Голосование по переизбранию руководящих должностных лиц',
                        href: 'https://www.testograf.ru/ru/blog/management-vote-template'
                    }
                ]
            }
        ]
    },
    {
        title: 'Разное',
        icon: SurveyIcon,
        sections: [
            {
                title: null,
                items: [
                    {
                        title: 'Опрос для выпускников университета',
                        href: 'https://www.testograf.ru/ru/blog/university-graduates-survey-template'
                    },
                    {
                        title: 'Опрос студентов для оценки преподавателей ВУЗа',
                        href: 'https://www.testograf.ru/ru/blog/student-survey-template'
                    },
                    {
                        title: 'Удовлетворенность студентов ВУЗа',
                        href: 'https://www.testograf.ru/ru/blog/student-satisfaction-survey-template'
                    }
                ]
            }
        ]
    }
];
