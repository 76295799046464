import { isBrowser } from './const';

const ua = isBrowser ? window.navigator.userAgent : '';

export const iOS = (): boolean =>
    isBrowser &&
    (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        (ua.includes('Mac') && 'ontouchend' in document));

export const isMobile = isBrowser && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);

const webViewUA = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    'Android.*(wv|.0.0.0);',
    // old chrome android webview agent
    'Linux; U; Android'
];

const webviewRegExp = new RegExp('(' + webViewUA.join('|') + ')', 'ig');

export const isWebview = isBrowser && !!ua.match(webviewRegExp);

export const isEmbedded = (() => {
    const iframed = (() => {
        try {
            return window.top !== window.self;
        } catch {
            return true;
        }
    })();

    return isBrowser && ((iframed && /embedded=true/gi.test(window.location.search)) || isWebview);
})();

export const isSourcePopup = (() => isBrowser && /source=popup/gi.test(window.location.search))();
export const isSourceWidget = (() => isBrowser && /source=widget/gi.test(window.location.search))();
