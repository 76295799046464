import dynamic from 'next/dynamic';

import 'swagger-ui-react/swagger-ui.css';

import { useFetch } from '@webapp/common/hooks/use-fetch';
import { createFileUrl } from '@webapp/common/lib/utils';
import { DownloadButton } from '@webapp/landing/src/components/ui/download-button';

import css from './doc/doc.css';

const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false
});

const CodeEditor = dynamic(() => import('@webapp/ui/lib/code-editor'), {
    ssr: false
});

const jsonOptions = {
    displayDataTypes: true,
    enableClipboard: true,
    collapsed: 2
};

const reactAceOptions = {
    readOnly: true,
    wrapEnabled: true
};

const aceOptions = {
    showLineNumbers: false,
    maxLines: Infinity
};

export const HookSchema: FC = () => {
    const xsd = useFetch(createFileUrl('schema.xsd'), 'raw');
    const response = useFetch(createFileUrl('response.json'), 'json');
    const survey = useFetch(createFileUrl('survey.json'), 'json');

    return (
        <div suppressHydrationWarning className={css.offer}>
            <h1>Веб-хуки</h1>
            <h2>JSON-формат</h2>
            <h3>Опрос</h3>
            <DownloadButton file='survey.json' />
            <div className={css.content}>
                <ReactJson src={survey} {...jsonOptions} />
            </div>
            <h3>Ответ</h3>
            <DownloadButton file='response.json' />
            <div className={css.content}>
                <ReactJson src={response} {...jsonOptions} />
            </div>
            <h2>XML-формат</h2>
            <h3>XSD-схема</h3>
            <DownloadButton file='schema.xsd' />
            <div className={css.content}>
                <CodeEditor aceOptions={aceOptions} defaultValue={xsd} mode='xml' reactAceOptions={reactAceOptions} />
            </div>
        </div>
    );
};
