import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class NestedListApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { extra, value } }, idx) => ({
                bool: true,
                text: value ? String(value) : null,
                longtext: extra ? String(extra) : null,
                int: idx + 1,
                answer: id
            })),
            comment: commentText
        };
    }

    public validate({ answers, items, params: { required } }: ISurveyQuestionModel): boolean {
        if (!required) {
            return true;
        }

        const firstNotAnswered = answers.find(({ response: { value } }) => !value);

        if (firstNotAnswered) {
            const notAnsweredLevel = firstNotAnswered.value[2] as string;
            const levelItems = items.get(notAnsweredLevel);
            const levelEmpty = levelItems ? levelItems.length === 0 : true;

            if (levelEmpty) return true;
        }

        return answers.every(({ response: { value } }) => !!value);
    }
}
