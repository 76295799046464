import { useEffect, useRef } from 'react';

import { createFileUrl } from '@webapp/common/lib/utils';
import { DownloadButton } from '@webapp/landing/src/components/ui/download-button';

import css from './doc.css';

export const Doc: FC<{
    file?: string;
    title: string;
    subTitle: string;
    html?: string;
}> = ({ file, subTitle, title, html }) => {
    const iframe = useRef(null);
    const url = createFileUrl(file);

    useEffect(() => {
        if (iframe.current) {
            iframe.current.src = `https://docs.google.com/viewer?embedded=true&url=${url}`;
        }
    }, [url]);

    return (
        <div className={css.offer}>
            <h1>{title}</h1>
            {subTitle && <h2 className={css.subheader}>{subTitle}</h2>}
            {file && <DownloadButton file={file} />}
            <div suppressHydrationWarning className={css.content}>
                {html ? (
                    <div dangerouslySetInnerHTML={{ __html: html }} className={css.htmlWrapper} />
                ) : (
                    <iframe frameBorder='0' height='800px' ref={iframe} width='100%' />
                )}
            </div>
        </div>
    );
};
