import { useEffect, useState } from 'react';

export const useFetch = (url: string, format: 'raw' | 'json'): any => {
    const [result, setResult] = useState(null);

    useEffect(() => {
        const fetchData = async (): Promise<any> => {
            try {
                let data = await fetch(url);
                data = format === 'json' ? await data.json() : await data.text();
                setResult(data);
            } catch (e) {
                setResult(null);
                console.error(e);
            }
        };

        void fetchData();
    }, [format, url]);

    return result;
};
