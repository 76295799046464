import type { AnyAction } from '@reduxjs/toolkit';

import * as Const from 'resources/profile/constants';
import type { UserType } from 'resources/profile/profile';

export const auth = (email: string, password: string): AnyAction => ({
    type: Const.PROFILE_AUTH_FETCH,
    email,
    password
});

export const getProfile = (auth: boolean): AnyAction => ({
    type: Const.PROFILE_GET_FETCH,
    auth
});

export const confirmDevice = (): AnyAction => ({
    type: Const.DEVICE_CONFIRM_FETCH
});

export const confirmDeviceCode = (code: string | number): AnyAction => ({
    type: Const.DEVICE_CONFIRM_CODE_FETCH,
    code
});

export const confirmDeviceHash = (hash): AnyAction => ({
    type: Const.DEVICE_CONFIRM_HASH_FETCH,
    hash
});

export const getQuotas = (): AnyAction => ({
    type: Const.QUOTAS_GET_FETCH
});

export const incUserLicence = (): AnyAction => ({
    type: Const.PROFILE_USE_USER_LICENCE
});

export const register = (email: string, password: string, name: string): AnyAction => ({
    type: Const.PROFILE_REGISTER_FETCH,
    email,
    password,
    name
});

export const resetPassword = (email: string): AnyAction => ({
    type: Const.PROFILE_RESET_PASSWORD_FETCH,
    email
});

export const confirmResetPassword = (token: string): AnyAction => ({
    type: Const.PROFILE_CONFIRM_RESET_PASSWORD_FETCH,
    token
});

export const changePassword = (password: string): AnyAction => ({
    type: Const.PROFILE_CHANGE_PASSWORD_FETCH,
    password
});

export const logout = (): AnyAction => ({
    type: Const.PROFILE_LOGOUT
});

export const confirm = (token: string): AnyAction => ({
    type: Const.PROFILE_CONFIRM_EMAIL_FETCH,
    token
});

export const resendConfirm = (): AnyAction => ({
    type: Const.PROFILE_RESEND_CONFIRM_EMAIL_FETCH
});

export const changeEmailUnconfirmed = (email: string): AnyAction => ({
    type: Const.PROFILE_CHANGE_EMAIL_UNCONFIRMED_FETCH,
    email
});

export const saveProfile = (data: AnyObject, profileType: UserType): AnyAction => ({
    type: Const.PROFILE_SAVE_FETCH,
    data,
    profileType
});
