import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class GroupFreeAnswersApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({ answer: id, text: value as string, bool: true })),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            return answers.every(({ response: { value } }) => !!value);
        }

        return true;
    }
}
