import cn from 'classnames';

import { ColorScheme } from '@webapp/common/lib/ui';

import { menuLinks } from './data';
import { MenuItems } from './items';

import css from './menu.css';

export const Menu: FC<{
    colorMode?: ColorScheme;
}> = ({ colorMode = ColorScheme.DARK }) => (
    <nav
        className={cn(css.menu, {
            [css.light]: colorMode === ColorScheme.LIGHT,
            [css.dark]: colorMode === ColorScheme.DARK
        })}
    >
        <MenuItems links={menuLinks} />
    </nav>
);
