import { Mastercard } from '@webapp/ui/lib/icons';
import AlfaClick from '@webapp/ui/lib/icons/pay/alfaclick.png';
import Cash from '@webapp/ui/lib/icons/pay/cash.png';
import Masterpass from '@webapp/ui/lib/icons/pay/masterpass.png';
import PSB1 from '@webapp/ui/lib/icons/pay/psb1.png';
import PSB2 from '@webapp/ui/lib/icons/pay/psb2.png';
import Visa from '@webapp/ui/lib/icons/pay/visa.png';
import WebMoney from '@webapp/ui/lib/icons/pay/webMoney.png';
import Yandex from '@webapp/ui/lib/icons/pay/yandex.png';

import { Advs } from 'components/ui/advs';

import css from './payment-variants.css';

const methods = [
    'Банковским переводом по счету на оплату',
    'Онлайн в личном кабинете (пластиковая карта, электронные деньги)'
];

export const PaymentVariants: FC = () => (
    <div className={css.paymentVariants}>
        <h1 className={css.header}>Тарифы</h1>
        <h2 className={css.subheader}>Способы оплаты</h2>
        <p className={css.description}>Мы принимаем оплаты от физических и юридических лиц следующими способами:</p>
        <Advs className={css.adv} items={methods} />
        <div className={css.paymentCards}>
            <div className={css.paymentCard}>
                <Mastercard />
                <img alt='visa' className={css.visa} src={Visa} />
            </div>
            <div className={css.paymentCard}>
                <img alt='master-pass' className={css.masterpass} src={Masterpass} />
            </div>
            <div className={css.paymentCard}>
                <img alt='alfa-click' className={css.alfaclick} src={AlfaClick} />
            </div>
            <div className={css.paymentCard}>
                <img alt='yandex' className={css.yandex} src={Yandex} />
            </div>
            <div className={css.paymentCard}>
                <img alt='web-money' className={css.webmoney} src={WebMoney} />
            </div>
            <div className={css.paymentCard}>
                <img alt='cash' className={css.cash} src={Cash} />
            </div>
            <div className={css.paymentCard}>
                <img alt='psb1' className={css.psb1} src={PSB1} />
                <img alt='psb2' className={css.psb2} src={PSB2} />
            </div>
        </div>
    </div>
);
