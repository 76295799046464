import { PLACEHOLDER } from 'resources/questions/fillers';

import { setHtml } from '@webapp/common/lib/ui';
import { Search } from '@webapp/ui/lib/icons';

import css from './help.css';

export const Help: FC<{ content: any }> = ({ content }) => {
    const onChange = (e) => {
        const containers = document.querySelectorAll('.help-container');

        containers.forEach((container) => {
            let hiddenChildrenCount = 0;

            Array.from(container.children).forEach((child: HTMLDivElement) => {
                const text = Array.from(child.children)[0].textContent.toLowerCase();

                if (!text.includes(e.target.value.trim().toLowerCase())) {
                    hiddenChildrenCount += 1;
                    child.style.display = 'none';
                } else {
                    child.style.display = 'block';
                }

                if (!e.target.value) {
                    hiddenChildrenCount = 0;
                }
            });

            if (hiddenChildrenCount === container.children.length) {
                container.parentElement.style.display = 'none';
            } else {
                container.parentElement.style.display = 'block';
            }
        });
    };

    return (
        <div>
            <div className={css.search}>
                <Search />
                <input defaultValue='' placeholder={PLACEHOLDER.SEARCH} onChange={onChange} />
            </div>
            <div className={css.help} {...setHtml(content.content)} />
        </div>
    );
};
