import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import { MatrixApi } from './common/matrix';

export class MatrixFewAnswersApi extends MatrixApi {
    public change(
        { answers, groups, params }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        const { maxLength, minLength } = params;

        const answerId = response.answer.id;

        if (params.maxLength) {
            const sumOfAnswers = {};
            groups.forEach((group) => {
                group.responses.forEach(({ answerId, response: { value } }) => {
                    if (!sumOfAnswers[answerId]) {
                        sumOfAnswers[answerId] = 0;
                    }
                    sumOfAnswers[answerId] += value ? 1 : 0;
                });
            });
            if (!sumOfAnswers[response.answer.id]) {
                sumOfAnswers[response.answer.id] = 0;
            }
            sumOfAnswers[response.answer.id] += value ? 1 : 0;
            if (sumOfAnswers[response.answer.id] > params.maxLength) {
                response.setValue(false);
            }
        }

        if (response.group.exception) {
            groups.map((group) => {
                if (group.id !== response.group.id) {
                    group.deleteResponse(answerId.toString());
                }
            });
        } else {
            groups.map((group) => {
                if (group.exception) {
                    group.deleteResponse(answerId.toString());
                }
            });
        }

        response.setValue(value);
        answers.forEach((answer) => {
            if (answer.valid) {
                return;
            }
            let isAnswered = 0;
            groups.forEach((group) => {
                if (group.responses.get(answer.id.toString())?.response.value) {
                    isAnswered += 1;
                }
            });
            if (!(!isAnswered || (maxLength && maxLength > isAnswered) || (minLength && minLength < isAnswered))) {
                answer.setValid(true);
            }
        });
    }

    public changeExtra(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(!!value);
        super.changeExtra(question, response, value);
    }
}
