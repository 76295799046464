import cn from 'classnames';

import css from './advs.css';

export const Advs: FC<{
    title?: string;
    items:
        | Array<string>
        | Array<
              | {
                    title: string;
                    href: string;
                }
              | { titleBig: string }
          >;
    className?: string;
}> = ({ className, items, title }) => (
    <section className={cn(css.advs, className)}>
        {title && <h3 className={css.advsTitle}>{title}</h3>}
        <ul>
            {items.map((template) => {
                if (typeof template === 'string') {
                    return (
                        <li key={template}>
                            <span>{template}</span>
                        </li>
                    );
                }
                if (template.titleBig) {
                    return (
                        <li className={css.titleBig} key={template.titleBig}>
                            <span>{template.titleBig}</span>
                        </li>
                    );
                }
                return (
                    <li key={template.title} title={template.title}>
                        <a href={template.href} rel='noreferrer' target='_blank'>
                            {template.title}
                        </a>
                    </li>
                );
            })}
        </ul>
    </section>
);
