import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { MatrixApi } from './common/matrix';

export class MatrixTextAnswerApi extends MatrixApi {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        if (question.invalid) {
            question.validate();
        }
        response.setValue(value);
    }

    public query({ commentText, groups, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: groups.flatMap(({ id: groupId, responses }) =>
                Object.entries(responses.toJSON()).map(([answerId, r]) => ({
                    group: groupId,
                    answer: parseInt(answerId),
                    bool: true,
                    text: String(r?.response?.value)
                }))
            ),
            comment: commentText
        };
    }

    public validate(data: ISurveyQuestionModel): boolean {
        const {
            answers,
            params: { required },
            groups
        } = data;

        if (required) {
            const allAnswersValidationList = [];

            answers.forEach((answer) => {
                const isAnswerValid = groups.every((group) => {
                    const responseValues = [...group.responses.values()];

                    const findAnswerInGroup = responseValues.find((response) => response.answerId === answer.id);

                    return findAnswerInGroup ? Boolean(findAnswerInGroup.response.value) : false;
                });

                answer.setValid(isAnswerValid);
                allAnswersValidationList.push(isAnswerValid);
            });

            return allAnswersValidationList.every((item) => item);
        }

        return true;
    }
}
