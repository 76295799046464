export const gtagSrc = 'https://www.googletagmanager.com/gtag/js?id=G-7VM4ZLRP3P';

export const gtagInit = `window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'G-7VM4ZLRP3P');`;

export const YaMetrikaCounter = `
       (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
       m[i].l=1*new Date();
       for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
       k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

       ym(26607360, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true
       });
    `;
