import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class SemDiffApi extends QuestionSimpleApi {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        response.setValue(value);
        question.groups.map((group) => {
            if (group.id !== response.group.id) {
                group.deleteResponse(response.answer.id);
            }
        });
    }

    public query({ commentText, groups, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: groups.flatMap(({ id: groupId, responses }) =>
                Object.keys(responses.toJSON()).map((key) => {
                    return {
                        group: groupId,
                        answer: parseInt(key),
                        bool: responses.get(key).response.value as boolean,
                        text: String(responses.get(key).response.extra)
                    };
                })
            ),
            comment: commentText
        };
    }

    public validate({ answers, groups, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            const answersIds: Array<string> = [];
            groups.forEach(({ responses }) =>
                Object.keys(responses.toJSON()).forEach((key) => {
                    if (responses.get(key).response.value) {
                        answersIds.push(key);
                    }
                })
            );
            answers.forEach(({ id, setValid }) => {
                setValid(answersIds.some((answer) => answer === id.toString()));
            });
            return answers.every(({ id }) => answersIds.find((answer) => answer === id.toString()));
        }

        return true;
    }
}
