import type { Placement } from '@popperjs/core';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { nop } from '@webapp/common/lib/utils';

import { Tooltip } from '../tooltip';

import css from './switch.css';

export const Switch: FC<{
    label?: string;
    labelClassname?: string;
    tooltip?: ReactNode;
    defaultChecked?: boolean;
    checked?: boolean;
    value?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean) => void;
    className?: string;
    tooltipPlacement?: Placement;
}> = ({
    labelClassname,
    children,
    checked,
    value,
    onChange = nop,
    defaultChecked,
    disabled,
    label,
    tooltip,
    tooltipPlacement,
    className
}) => {
    const [innerChecked, setInnerChecked] = useState<boolean>(defaultChecked);
    let finalChecked = typeof checked !== 'undefined' ? checked : innerChecked;
    finalChecked = typeof value !== 'undefined' ? value : finalChecked; // TODO FIXME used in Forms only

    const handleChange = (): void => {
        if (disabled) {
            return;
        }
        const next = !finalChecked;
        setInnerChecked(next);
        onChange(next);
    };

    useEffect(() => {
        setInnerChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <div className={cn(css.switch, { [css.disabled]: disabled }, className)} onClick={handleChange}>
            <div className={cn(css.control, { [css.checked]: finalChecked })} />
            {(label || tooltip) && (
                <div className={cn(css.label, labelClassname)}>
                    {label && <span className={css.text}>{label}</span>}
                    {children}
                    {tooltip && (
                        <Tooltip preventClick placement={tooltipPlacement}>
                            {tooltip}
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
};
