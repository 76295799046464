import dayjs from 'dayjs';

import { DATE_FORMAT, DATETIME_FORMAT, DateTimeType, TIME_FORMAT } from '@webapp/common/lib/date';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

const convertDatetime = (
    dateType: DateTimeType,
    answers: Array<{ answer: number; text: string; bool: boolean }>
): any => {
    if (!Array.isArray(answers)) {
        return answers;
    }

    return answers.map((answer) => {
        let text = '';
        const date = dayjs(answer.text);
        switch (dateType) {
            case DateTimeType.DATE: {
                text = date.format(DATE_FORMAT);
                break;
            }
            case DateTimeType.DATE_TIME: {
                text = date.format(DATETIME_FORMAT);
                break;
            }
            case DateTimeType.TIME: {
                text = date.format(TIME_FORMAT);
                break;
            }
        }
        return { ...answer, text };
    });
};

export class DatetimeApi extends QuestionSimpleApi {
    public query({ answers, commentText, id, params: { dateType } }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: convertDatetime(
                dateType,
                answers.map(({ id, response: { value } }) => ({
                    answer: id,
                    text: String(value ? String(value) : ''),
                    bool: true
                }))
            ),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (!required) return true;

        const notEmptyAnswers = answers.reduce((acc, answer) => acc + Number(Boolean(answer.response.value)), 0);

        return notEmptyAnswers === answers.length;
    }
}
