/* pack1 */
import cross from './cross.svg';
import plus from './plus.svg';
import angry1 from './smiles/pack1/angry.svg';
import app1 from './smiles/pack1/approval.svg';
import bad1 from './smiles/pack1/bad.svg';
import good1 from './smiles/pack1/good.svg';
import normal1 from './smiles/pack1/normal.svg';
import not_app1 from './smiles/pack1/not-approval.svg';
import perfect1 from './smiles/pack1/perfect.svg';
/* pack2 */
import angry2 from './smiles/pack2/angry.svg';
import app2 from './smiles/pack2/approval.svg';
import bad2 from './smiles/pack2/bad.svg';
import good2 from './smiles/pack2/good.svg';
import normal2 from './smiles/pack2/normal.svg';
import not_app2 from './smiles/pack2/not-approval.svg';
import perfect2 from './smiles/pack2/perfect.svg';
/* pack3 */
import angry3 from './smiles/pack3/angry.svg';
import app3 from './smiles/pack3/approval.svg';
import bad3 from './smiles/pack3/bad.svg';
import good3 from './smiles/pack3/good.svg';
import normal3 from './smiles/pack3/normal.svg';
import not_app3 from './smiles/pack3/not-approval.svg';
import perfect3 from './smiles/pack3/perfect.svg';
/* pack4 */

export enum SmilePackName {
    PACK1 = 'pack1',
    PACK2 = 'pack2',
    PACK3 = 'pack3',
    PACK4 = 'pack4'
}

export enum SmileName {
    ANGRY = 'angry',
    APPROVAL = 'approval',
    BAD = 'bad',
    GOOD = 'good',
    NORMAL = 'normal',
    NOT_APPROVAL = 'not-approval',
    PERFECT = 'perfect',
    PLUS = 'plus',
    CROSS = 'cross'
}

export const SmilePacks = {
    [SmilePackName.PACK1]: {
        [SmileName.ANGRY]: angry1,
        [SmileName.APPROVAL]: app1,
        [SmileName.BAD]: bad1,
        [SmileName.GOOD]: good1,
        [SmileName.NORMAL]: normal1,
        [SmileName.NOT_APPROVAL]: not_app1,
        [SmileName.PERFECT]: perfect1
    },
    [SmilePackName.PACK2]: {
        [SmileName.ANGRY]: angry2,
        [SmileName.APPROVAL]: app2,
        [SmileName.BAD]: bad2,
        [SmileName.GOOD]: good2,
        [SmileName.NORMAL]: normal2,
        [SmileName.NOT_APPROVAL]: not_app2,
        [SmileName.PERFECT]: perfect2
    },
    [SmilePackName.PACK3]: {
        [SmileName.ANGRY]: angry3,
        [SmileName.APPROVAL]: app3,
        [SmileName.BAD]: bad3,
        [SmileName.GOOD]: good3,
        [SmileName.NORMAL]: normal3,
        [SmileName.NOT_APPROVAL]: not_app3,
        [SmileName.PERFECT]: perfect3
    },
    [SmilePackName.PACK4]: {
        [SmileName.ANGRY]: cross,
        [SmileName.APPROVAL]: cross,
        [SmileName.BAD]: cross,
        [SmileName.GOOD]: cross,
        [SmileName.NORMAL]: cross,
        [SmileName.NOT_APPROVAL]: cross,
        [SmileName.PERFECT]: cross,
        [SmileName.PLUS]: plus,
        [SmileName.CROSS]: cross
    }
};
