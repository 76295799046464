import { LicenseNames, LicenseType } from '@webapp/account/src/resources/profile/license';

import css from './functions.css';

export const MAX = 'MAX';

export enum Options {
    diskVolume,
    surveyCount,
    responseCount,
    addUsers,
    period,
    importList,
    copy,
    autosave,
    shortLink,
    passwordAccess,
    ipRestriction,
    welcomePage,
    showOptions,
    timer,
    progress,
    random,
    helpText,
    audio,
    typeSurvey,
    typeList,
    typeImage,
    typeDropdown,
    typeRank,
    typeDistScale,
    typeSemDiff,
    typeNps,
    typeMatrix,
    typeFreeAnswer,
    typeUpload,
    typeContacts,
    typeText,
    typeTests,
    typeSingleMultiple,
    typeScore,
    typeTestText,
    typeTestRank,
    logicSingle,
    logicMultiple,
    logicMatrix,
    logicShowOrHide,
    logicQuestions,
    logicDeferred,
    logicFinish,
    logicQuotas,
    finishRegular,
    finishPersonal,
    finishDisqual,
    finishRedirect,
    finishResults,
    finishPromo,
    brandLogo,
    brandStyles,
    brandText,
    brandCss,
    brandNoTT,
    shareEmail,
    shareSMTP,
    shareSms,
    shareOfflineMode,
    shareLinks,
    shareWidget,
    sharePopup,
    shareEmbed,
    shareExport,
    qrCode,
    resultsStat,
    resultsWordCloud,
    resultsBuildingCrossTables,
    resultsIncompleteSave,
    resultsOfflineMode,
    resultsCheck,
    resultsLink,
    resultsEmail,
    resultsFilter,
    resultsCommon,
    resultsSingle,
    resultsHooks,
    api,
    secureLevel,
    secure2FA,
    secureSsl,
    secureDdos,
    secureWAF,
    secureBackup,
    secureEncryptionBackup,
    secureDataStorage,
    docsAgreement,
    docsNda,
    docsAccounting,
    appointmentOfAdmins,
    settingsAccessFolders,
    customFonts
}

export const types = [
    {
        title: <span>{LicenseNames[LicenseType.ONETIME]}</span>,
        [Options.diskVolume]: '2 Гб',
        [Options.surveyCount]: '1',
        [Options.responseCount]: '10 000',
        [Options.addUsers]: false,
        [Options.period]: '60 дней',
        [Options.shareEmail]: '2 000 всего',
        [Options.shareSMTP]: false,
        [Options.shareSms]: false,
        [Options.shareOfflineMode]: false,
        [Options.resultsFilter]: MAX,
        [Options.appointmentOfAdmins]: false,
        [Options.settingsAccessFolders]: false,
        [Options.api]: false,
        [Options.secureLevel]: 'Стандартная',
        [Options.resultsBuildingCrossTables]: false,
        [Options.resultsIncompleteSave]: false,
        [Options.resultsOfflineMode]: false,
        [Options.docsAgreement]: false,
        [Options.docsNda]: false,
        [Options.shareLinks]: '1',
        [Options.brandNoTT]: false,
        [Options.customFonts]: false
    },
    {
        title: <span>{LicenseNames[LicenseType.FORYEAR]}</span>,
        [Options.diskVolume]: '10 Гб',
        [Options.surveyCount]: MAX,
        [Options.responseCount]: MAX,
        [Options.addUsers]: true,
        [Options.period]: '1 год',
        [Options.shareEmail]: '10 000 в сутки',
        [Options.shareSMTP]: false,
        [Options.shareSms]: false,
        [Options.shareOfflineMode]: false,
        [Options.resultsFilter]: MAX,
        [Options.api]: false,
        [Options.secureLevel]: 'Стандартная',
        [Options.resultsBuildingCrossTables]: false,
        [Options.resultsIncompleteSave]: false,
        [Options.resultsOfflineMode]: false,
        [Options.docsAgreement]: 'Типовой',
        [Options.docsNda]: false,
        [Options.shareLinks]: '1',
        [Options.brandNoTT]: false,
        [Options.customFonts]: false
    },
    {
        title: (
            <span>
                <span>Годовая лицензия</span> <span className={css.blue}>PRO+</span>
            </span>
        ),
        [Options.diskVolume]: '15 Гб',
        [Options.surveyCount]: MAX,
        [Options.responseCount]: MAX,
        [Options.addUsers]: true,
        [Options.period]: '1 год',
        [Options.shareEmail]: '15 000 в сутки',
        [Options.shareSms]: true,
        [Options.shareOfflineMode]: true,
        [Options.resultsFilter]: MAX,
        [Options.api]: true,
        [Options.secureLevel]: 'Повышенная',
        [Options.docsAgreement]: 'Индивидуальное согласование',
        [Options.docsNda]: true,
        [Options.shareLinks]: '50',
        [Options.customFonts]: true
    }
];

export const sections: Array<{
    title: string;
    items: Record<number, string>;
}> = [
    {
        title: 'Организация работы',
        items: {
            [Options.diskVolume]: 'Место на диске',
            [Options.surveyCount]: 'Количество опросов',
            [Options.responseCount]: 'Количество ответов',
            [Options.period]: 'Срок использования'
        }
    },
    {
        title: 'Создание',
        items: {
            [Options.importList]: 'Импорт вопросов списком',
            [Options.copy]: 'Копирование вопроса / опроса / текста',
            [Options.autosave]: 'Автосохранение изменений',
            [Options.shortLink]: 'Именной URL(короткая ссылка)',
            [Options.passwordAccess]: 'Доступ по паролю / авторизация через ВКонтакте',
            [Options.ipRestriction]: 'Ограничение по IP-адресу / устройству / ссылке',
            [Options.welcomePage]: 'Страница приветствия',
            [Options.showOptions]: 'Показ полотном / по страницам / по одному вопросу',
            [Options.timer]: 'Таймер для вопроса / опроса',
            [Options.progress]: 'Индикатор выполнения',
            [Options.random]: 'Рандомизация вопросов / ответов',
            [Options.helpText]: 'Подсказка к вопросу / Комментарий к ответу',
            [Options.audio]: 'Запись аудио'
        }
    },
    {
        title: 'Типы вопросов',
        items: {
            [Options.typeSurvey]: 'Опросы',
            [Options.typeList]: 'Один / несколько ответов (+ свой ответ)',
            [Options.typeImage]: 'Одно / несколько изображений',
            [Options.typeDropdown]: 'Выпадающий список / Вложенные списки',
            [Options.typeRank]: 'Ранжирование',
            [Options.typeDistScale]: 'Распределительная шкала',
            [Options.typeSemDiff]: 'Семантический дифференциал',
            [Options.typeNps]: 'NPS, CSI, шкала, звездный рейтинг, смайл рейтинг',
            [Options.typeMatrix]:
                'Матричные (один ответ / несколько ответов / звездный рейтинг / текстовый ответ / выпадающий список)',
            [Options.typeFreeAnswer]: 'Свободный ответ (короткий текст / длинный текст / группа ответов / число)',
            [Options.typeUpload]: 'Загрузка файлов',
            [Options.typeContacts]:
                'Контактные данные (имя / email / телефон / место жительства / дата / время / галочка-согласие)',
            [Options.typeText]: 'Текст между вопросами',
            [Options.typeTests]: 'Тесты',
            [Options.typeSingleMultiple]: 'Тесты: Один / несколько ответов',
            [Options.typeScore]: 'Тесты: Верно / неверно, назначение баллов',
            [Options.typeTestText]: 'Тесты: Текст',
            [Options.typeTestRank]: 'Тесты: Порядок'
        }
    },
    {
        title: 'Логика / ветвление',
        items: {
            [Options.logicSingle]: 'Для типов вопросов с выбором одного ответа',
            [Options.logicMultiple]: 'Для типов вопросов с выбором нескольких ответов',
            [Options.logicMatrix]: 'Для матричных типов вопросов',
            [Options.logicShowOrHide]: 'Логика скрытия / показа вопрос / страниц',
            [Options.logicQuestions]: 'Продвинутая логика привязки ответов к определенным вопросам / страницам',
            [Options.logicDeferred]: 'Отложенная логика',
            [Options.logicFinish]: 'Дисквалификация / редиректы / индивидуальные завершения',
            [Options.logicQuotas]: 'Квотирование для ответов и всего опроса'
        }
    },
    {
        title: 'Завершение',
        items: {
            [Options.finishRegular]: 'Стандартное завершение',
            [Options.finishPersonal]: 'Индивидуальное завершение',
            [Options.finishDisqual]: 'Страница дисквалификации',
            [Options.finishRedirect]: 'Перенаправление на опрос / сайт после завершения',
            [Options.finishResults]: 'Результаты опроса / теста после завершения',
            [Options.finishPromo]: 'Присвоение промокодов'
        }
    },
    {
        title: 'Брендирование',
        items: {
            [Options.brandLogo]: 'Добавление логотипа',
            [Options.brandStyles]: 'Корпоративные цвета / шрифты / фон',
            [Options.brandText]: 'Переименовывание кнопок / оповещений',
            [Options.brandCss]: 'CSS стили',
            [Options.customFonts]: 'Загрузка кастомных шрифтов'
        }
    },
    {
        title: 'Распространение',
        items: {
            [Options.shareEmail]: 'Email рассылка',
            [Options.shareSMTP]: 'Свой SMTP',
            [Options.shareSms]: 'Sms рассылка',
            [Options.shareOfflineMode]: 'Оффлайн режим',
            [Options.shareLinks]: 'Параметры ссылки',
            [Options.shareWidget]: 'Виджет опроса',
            [Options.sharePopup]: 'Всплывающее окно',
            [Options.shareEmbed]: 'Встраивание на сайт',
            [Options.shareExport]: 'Выгрузка анкеты (DOCX)',
            [Options.qrCode]: 'QR-код'
        }
    },
    {
        title: 'Результаты',
        items: {
            [Options.resultsStat]: 'Статистика выполнения',
            [Options.resultsWordCloud]: 'Облако слов (анализ открытых вопросов)',
            [Options.resultsBuildingCrossTables]: 'Построение кросс-таблиц',
            [Options.resultsIncompleteSave]: 'Сохранение незавершенных ответов',
            [Options.resultsCheck]: 'Проверка корректности выполнения',
            [Options.resultsLink]: 'Публичная ссылка на результаты / результаты после опроса',
            [Options.resultsEmail]: 'Email уведомления о новых ответах / логика отправки',
            [Options.resultsFilter]: 'Фильтрация ответов',
            [Options.resultsCommon]: 'Сводный отчет (в ЛК, .CSV, .SPSS, .XLSX, .PDF)',
            [Options.resultsSingle]: 'Отдельные ответы (в ЛК, .DOC, .ZIP)',
            [Options.resultsHooks]: 'Веб-хуки (XML, JSON)'
        }
    },
    {
        title: 'Совместная работа',
        items: {
            [Options.appointmentOfAdmins]: 'Назначение админов',
            [Options.settingsAccessFolders]: 'Настройка доступа к папкам'
        }
    },
    {
        title: 'Интеграции',
        items: {
            [Options.api]: 'API'
        }
    },
    {
        title: 'Безопасность',
        items: {
            [Options.secure2FA]: 'Двухфакторная аутентификация',
            [Options.secureSsl]: 'SSL сертификат',
            [Options.secureDdos]: 'Защита от DDoS-атак',
            [Options.secureWAF]: 'WAF файрвол веб-приложений',
            [Options.secureBackup]: 'Ежедневные back-up',
            [Options.secureEncryptionBackup]: 'Шифрование back-up',
            [Options.secureDataStorage]: 'Удалённые датацентры для хранения данных'
        }
    },
    {
        title: 'Документы',
        items: {
            [Options.docsAgreement]: 'Договор',
            [Options.docsAccounting]: 'Закрывающие документы'
        }
    }
];
