import { hasLimit, LICENSE_AVAILABLE, LicenseType, SurveyLimit } from 'resources/profile/license';
import type { LicenceInfo, License, OneTimeLicense } from 'resources/profile/license';
import type { Profile, UserStatus } from 'resources/profile/profile';
import { selectSurveyCount } from 'resources/survey/selectors';

import type { States } from '../states';

import type { ProfileState } from './state';

export const selectProfileState = ({ Profile }: States): ProfileState => Profile;

export const selectProfile = (states: States): Profile => {
    const p = selectProfileState(states);

    return p.profile;
};

export const selectFIO = (states: States): string => {
    const p = selectProfile(states);

    return [p.name, p.patronymic, p.surname].filter(Boolean).join(' ');
};

export const selectProfileEditable = (states: States): Partial<Profile> => {
    const {
        city,
        companyName,
        country,
        inn,
        kpp,
        name,
        nikname,
        ogrn,
        patronymic,
        room,
        street,
        surname,
        zipCode,
        id
    } = selectProfile(states);

    return {
        city,
        companyName,
        country,
        inn,
        kpp,
        name,
        ogrn,
        nikname,
        patronymic,
        room,
        street,
        surname,
        zipCode,
        id
    };
};

export const selectProfileFetch = (states: States): boolean => {
    const p = selectProfileState(states);

    return p.fetch;
};

export const selectProfileAuthSuccess = (states: States): boolean => {
    const p = selectProfileState(states);

    return p.authSuccess;
};

export const selectProfileFetchSuccess = (states: States): string => {
    const p = selectProfileState(states);

    return p.fetchSuccess;
};

export const selectProfileFetchError = (states: States): string => {
    const p = selectProfileState(states);

    return p.fetchError;
};

export const selectProfileFetchErrorMessage = (states: States): string => {
    const p = selectProfileState(states);

    return p.fetchErrorMessage;
};

export const selectProfileRestricted = (states: States): boolean => {
    const p = selectProfile(states);

    return p.restricted;
};

export const selectUserStatus = (states: States): UserStatus => {
    const p = selectProfile(states);

    return p.userStatus;
};

export const selectConfirmDeviceResult = (states: States): boolean => {
    const p = selectProfile(states);

    return p.confirmDeviceResult;
};

export const selectConfirmDeviceMessage = (states: States): string => {
    const p = selectProfile(states);

    return p.confirmDeviceMessage;
};

export const selectProfileLoading = (states: States): boolean => {
    const p = selectProfileState(states);

    return p.profileLoading;
};

export const selectProfileId = (states: States): number => {
    const p = selectProfile(states);

    return p.id;
};

export const selectProfileUserHasPermissions = (states: States): boolean => {
    const p = selectProfile(states);

    if (p.superadminUser) {
        return p.superadminPermission;
    }

    return true;
};

export const selectProfileEmail = (states: States): string => {
    const p = selectProfile(states);

    return p.email;
};

export const selectLicense = (states: States): License => {
    const p = selectProfileState(states);

    return p.license;
};

export const selectSurveyQuotasById =
    (surveyId: number) =>
    (states: States): OneTimeLicense => {
        const { surveys } = selectLicense(states);

        return surveys.find(({ id }) => id === surveyId);
    };

export const selectLicenseInfo = (states: States): LicenceInfo => {
    const { licenseId, oneCount, oneCountUsed, surveys, userCount, volume, volumeUsed, withActiveLicense } =
        selectLicense(states);
    const fetch = selectProfileFetch(states) || selectProfileLoading(states);

    const isPro = [LicenseType.FORYEAR, LicenseType.FORYEARPLUS].includes(licenseId);
    const isDemo = licenseId === LicenseType.DEMO;
    const isProPlus = licenseId === LicenseType.FORYEARPLUS;
    const isSingle = licenseId === LicenseType.ONETIME;
    const availableLicenseNumber = isPro ? 0 : Math.max(0, oneCount - oneCountUsed);
    const hasAvailableUsers = isPro && userCount > 0;
    const isNoLicense = !LICENSE_AVAILABLE.includes(licenseId);
    const yearVolumeExceeded = !isSingle && volume - volumeUsed <= 0; // TODO use flags core#224
    const singleVolumeExceeded = surveys.some(({ limits }) => hasLimit(limits, SurveyLimit.Volume));
    const isSizeExceeded = !fetch && (yearVolumeExceeded || singleVolumeExceeded);

    return {
        isDemo,
        isPro,
        isProPlus,
        isSizeExceeded,
        licenseId,
        availableLicenseNumber,
        hasAvailableUsers,
        isSingle,
        isNoLicense,
        withActiveLicense
    };
};

export const selectCanCreateSurvey = (states: States): boolean => {
    const { availableLicenseNumber, isNoLicense, isPro } = selectLicenseInfo(states);
    const survey = selectSurveyCount(states);

    return !!(isPro || availableLicenseNumber || (isNoLicense && survey === 0));
};

export const selectCanDeleteSurvey = (states: States): boolean => {
    const { isNoLicense } = selectLicenseInfo(states);

    return !isNoLicense;
};

export const selectCanGetToken = (states: States): boolean => {
    const l = selectLicense(states);

    return l.licenseId === LicenseType.FORYEARPLUS;
};

export const selectAnyLimitLow = (states: States): boolean => {
    const l = selectLicense(states);

    return l.surveys.some(({ lowLimit }) => lowLimit !== SurveyLimit.All);
};
