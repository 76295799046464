export enum CssQuestionType {
    QUESTION = 'question',
    AGREEMENT = 'agreement',
    DATETIME = 'datetime',
    DROPDOWN = 'dropdown',
    EMAIL = 'email',
    FILE_UPLOAD = 'fileUpload',
    IMAGE_SELECT = 'imageSelect',
    GROUP_FREE_ANSWERS = 'groupFreeAnswers',
    LIST_SELECT = 'listSelect',
    LONG_TEXT = 'longText',
    MATRIX_DROPDOWN = 'matrixDropdown',
    MATRIX_LIST_SELECT = 'matrixListSelect',
    MATRIX_TEXT = 'matrixText',
    NAME = 'name',
    NPS = 'nps',
    NUMBER = 'number',
    PHONE = 'phone',
    RANK = 'rank',
    RESIDENCE = 'residence',
    SCALE = 'scale',
    SCALE_DISTRIBUTE = 'scaleDistribute',
    SEM_DIFF = 'semDiff',
    SHORT_TEXT = 'shortText',
    SMILES = 'smiles',
    STARS = 'stars',
    START_MATRIX = 'starsMatrix',
    TEXT_BLOCK = 'textBlock',
    CSI = 'csi'
}

export enum CssPageBlock {
    QUESTION = 'question',
    QUESTION_HEADER = 'header',
    QUESTION_ORDER = 'order',
    QUESTION_TITLE = 'title',
    QUESTION_TIMER = 'timer',
    QUESTION_HELP = 'help',
    BACKGROUND_COLOR = 'backgroundColor',
    SURVEY_INNER = 'surveyInner',
    CONTENT = 'content',
    HEADER = 'header',
    HEADER_TITLE_CONTAINER = 'titleContainer',
    HEADER_TITLE = 'title',
    HEADER_LOGO_CONTAINER = 'logoContainer',
    HEADER_LOGO = 'logo',
    SECTION = 'section',
    START = 'start',
    WELCOME = 'welcome',
    NAVIGATION = 'navigation',
    ANSWER = 'answer'
}

export enum CssUiComponent {
    NO_PRINT = 'noPrint',
    BUTTON = 'button',
    BUTTON_LOADER = 'buttonLoader',
    BUTTON_TEXT = 'buttonText',
    MODAL = 'modal',
    REQUIRED = 'required',
    INVALID = 'invalid',
    CELL = 'cell',
    CHECKBOX = 'checkbox',
    CHECKBOX_MARK = 'checkboxMark',
    CHECKBOX_SELECTED = 'checkboxSelected',
    LABEL = 'label',
    LIST = 'list',
    INPUT = 'input',
    INPUT_WRAP = 'inputWrap',
    SLIDER = 'slider',
    SCROLL = 'scroll',
    SCROLL_CONTENT = 'content',
    SCROLL_THUMB = 'thumb',
    SCROLL_VBAR = 'vbar',
    SCROLL_HBAR = 'hbar',
    SELECT = 'select',
    SELECT_OPTIONS = 'options',
    SELECT_OPTION = 'option',
    TABLE = 'table',
    WYSIWYG = 'wysiwyg'
}

export const PLACEHOLDER = {
    CANT_RATE: 'Не могу оценить'
};
