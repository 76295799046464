import { isEmail } from '@webapp/common/lib/ui';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class EmailApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({ answer: id, text: value as string, bool: true })),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (!required) return true;

        const validEmailCount = answers.reduce(
            (acc, { response: { value } }) => acc + Number(!!value && isEmail(value as string)),
            0
        );

        return validEmailCount === answers.length;
    }
}
