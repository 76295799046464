import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import { MatrixApi } from './common/matrix';

export class MatrixSingleAnswerApi extends MatrixApi {
    public change(
        { answers, groups }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        const answerId = response.answer.id;
        const respGroupId = response.group.id;
        groups.forEach((group) => {
            if (respGroupId !== group.id) {
                group.deleteResponse(answerId.toString());
            }
        });
        response.setValue(value);

        answers.forEach((answer) => {
            if (answer.valid) {
                return;
            }
            let isAnswered = false;
            groups.forEach((group) => {
                const id = answer.id.toString();
                const resp = group.responses.get(id);
                if (resp?.response.value) {
                    isAnswered = true;
                }
            });
            if (isAnswered) {
                answer.setValid(true);
            }
        });
    }
}
