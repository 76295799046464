import cn from 'classnames';

import { AccountRoutes } from '@webapp/account/src/resources/routes';
import { useAuthInfoConnect } from '@webapp/common/hooks/use-auth-info-connect';
import { ColorScheme } from '@webapp/common/lib/ui';
import { Cross } from '@webapp/ui/lib/icons';

import { Menu } from '../menu';

import css from './nav.css';

const NotAuth: FC = () => (
    <>
        <div className={css.item}>
            <a className={css.link} href={AccountRoutes.authLogin.link}>
                Вход
            </a>
        </div>
        <div className={css.item}>
            <a className={css.link} href={AccountRoutes.authRegister.link}>
                Регистрация
            </a>
        </div>
    </>
);

const Auth: FC<{
    onLogout: () => void;
}> = ({ onLogout }) => (
    <div className={css.item}>
        <a className={css.link} href='' onClick={onLogout}>
            Выход
        </a>
    </div>
);

export const Nav: FC<{
    active?: boolean;
    onClose: (e) => void;
}> = ({ active, onClose }) => {
    const { isLoggedIn, onLogout } = useAuthInfoConnect();

    return (
        <div className={cn(css.nav, { [css.active]: active })}>
            <div className={css.close}>
                <Cross className={css.btn} onClick={onClose} />
            </div>
            <nav className={cn(css.links, css.inline)}>{!isLoggedIn && <NotAuth />}</nav>
            <div className={css.content}>
                <div className={css.scrollable}>
                    <Menu colorMode={ColorScheme.LIGHT} />
                    <nav className={cn(css.links, css.inline)}>{isLoggedIn && <Auth onLogout={onLogout} />}</nav>
                    <div className={css.contacts}>
                        <nav className={cn(css.links, css.tight)}>
                            <div className={css.item}>
                                <a className={css.link} href='tel:+74951206519'>
                                    +7(495) 120-65-19
                                </a>
                            </div>
                            <div className={css.item}>
                                <a className={css.link} href='mailto:info@testograf.ru'>
                                    info@testograf.ru
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};
