import type { AnswersRequest, QuestionApi } from '@webapp/common/resources/mst-survey/api/queries-api';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

export abstract class QuestionSimpleApi implements Partial<QuestionApi> {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        response.setValue(value);
    }

    public changeExtra(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setExtra(value);
    }

    public abstract query(q: ISurveyQuestionModel): AnswersRequest;

    public validate({ params: { required }, response: { value } }: ISurveyQuestionModel): boolean {
        if (required) {
            return !!value;
        }

        return true;
    }
}
