import { stringNotEmpty } from '@webapp/common/lib/utils';
import type { AnswersRequest, QuestionApi } from '@webapp/common/resources/mst-survey/api/queries-api';
import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';
import { AnswerType, QuestionType } from '@webapp/common/resources/survey';

const checkGroups = (groups, answerId, answerType): [number, boolean] => {
    let answeredCount = 0;
    let haveException = false;

    groups.forEach((group) => {
        const response = group.responses.get(answerId)?.response;

        if (!response) return;

        const value = response?.value;
        const { exception, type } = group;

        if (exception && value) {
            answeredCount = 1;
            haveException = true;
            return;
        }

        //checkbox with self
        // TODO radio with self failed here
        if (answerType === AnswerType.CHECKBOX && type === AnswerType.SELF) {
            if (value && stringNotEmpty(response?.extra)) {
                answeredCount += 1;
            }
            return;
        }

        switch (type) {
            case AnswerType.SELF:
                const text = response?.extra;
                if (value && text && stringNotEmpty(text)) {
                    answeredCount += 1;
                }
                break;
            case AnswerType.SELECT:
            case AnswerType.CHECKBOX:
            case AnswerType.TEXT:
            case AnswerType.RADIO:
                if (value) {
                    answeredCount += 1;
                }
                break;
        }
    });

    return [answeredCount, haveException];
};

const processAnswers = (question, answerType): void => {
    const { answers, groups, params, type } = question;
    const { maxLength, minLength, required } = params;
    const answerWithText = answerType === AnswerType.TEXT || answerType === AnswerType.SELECT;
    const haveMaxLength = maxLength !== null;
    const haveMinLength = minLength !== null;

    answers.forEach((answer) => {
        const [answeredCount, haveException] = checkGroups(groups, answer.id, answerType);

        let answerValid: boolean = (() => {
            switch (type) {
                case QuestionType.MATRIX_DROPDOWN_LIST:
                    return answeredCount === groups.length;
                default:
                    return answeredCount > 0;
            }
        })();

        if (answerWithText && required && !haveMaxLength && !haveMinLength) {
            answerValid = answerValid && answeredCount === groups.length;
        }

        if (haveMinLength) {
            answerValid = answerValid && answeredCount >= minLength;
        }

        if (haveMaxLength) {
            answerValid = answerValid && answeredCount <= maxLength;
        }

        answerValid = haveException || answerValid;

        answer.setAnsweredCount(answeredCount);
        answer.setValid(answerValid);
    });
};

export const validateWithSelf = (question: ISurveyQuestionModel, answerType: AnswerType): boolean => {
    const { answers, commentText, params, response } = question;
    const { commentRequired, required } = params;

    if (!required) return true;

    processAnswers(question, answerType);

    const validAnswers = answers.every((answer) => !!answer.valid);
    const validComment = commentRequired ? !!commentText : true;

    if (commentRequired) {
        response.setCommentInvalid(!validComment);
    }

    return validAnswers && validComment;
};

export abstract class MatrixApi implements QuestionApi {
    public abstract change(q: ISurveyQuestionModel, r: ISurveyQuestionResponseModel, v: PrimitiveValue): void;

    public changeExtra(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setExtra(value);
    }

    public query({ commentText, groups, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: groups.flatMap(({ id: group, responses }) =>
                Object.entries(responses.toJSON()).map(
                    ([
                        answerId,
                        {
                            response: { extra, value }
                        }
                    ]) => ({
                        group,
                        answer: parseInt(answerId),
                        bool: Boolean(value),
                        text: String(extra) || undefined
                    })
                )
            ),
            comment: commentText
        };
    }

    public validate(question: ISurveyQuestionModel): boolean {
        return validateWithSelf(question, AnswerType.CHECKBOX);
    }
}
