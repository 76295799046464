import { plans } from './items';
import { Plan } from './plan';

import css from './pricing-plans.css';

export const PricingPlans: FC = () => (
    <div className={css.pricingPlans}>
        <h1>Тарифы</h1>
        <h2>Тарифные планы</h2>
        <div className={css.plans}>
            {plans.map(({ header, hint, items, price }) => (
                <Plan header={header} hint={hint} items={items} key={price} price={price} />
            ))}
        </div>
    </div>
);
