export const cookieHTML = `<div class="docHTML">
<p><strong>Общие положения</strong></p>
<p>Общество с ограниченной ответственностью &laquo;Тестограф&raquo; (далее &mdash; ООО &laquo;Тестограф&raquo;) обрабатывает данные, указанные в настоящей Политике, собранные в процессе использования посетителями (пользователями / респондентами) сайта <a href="https://www.testograf.ru" target="_blank">https://www.testograf.ru</a> (далее &ndash; Сайт). При нажатии кнопки &laquo;Понятно&raquo; в окне-уведомлении об обработке Cookie, Вы даете свое согласие на обработку ООО &laquo;Тестограф&raquo; Ваших Cookie-файлов.</p>
<p><strong>Что такое Cookie-файлы</strong></p>
<p>Сookie-файлы &mdash; это небольшой фрагмент данных, который Сайт запрашивает у браузера, используемого на Вашем компьютере или мобильном устройстве. Cookie-файлы содержат в себе информацию о Ваших действиях на Сайте, а также могут содержать сведения о Вашем оборудовании, дату и время пользовательской сессии. Сookie-файлы хранятся локально на Вашем компьютере или мобильном устройстве. При желании Вы можете удалить сохраненные Cookie-файлы в настройках браузера.</p>
<p><strong>Виды Cookie-файлов, собираемых на Сайте и цели их использования</strong></p>
<p><strong>Сессионные</strong></p>
<p>Существуют только во временной памяти в течение времени, когда пользователь Сайта находится на странице Сайта. Браузеры обычно удаляют сессионные Cookie после того, как Вы закрываете окно Сайта. Сессионные Cookie позволяют Сайту помнить информацию о Вашем выборе на предыдущей странице сайта, чтобы избежать необходимости повторного ввода информации.</p>
<p><strong>Постоянные</strong></p>
<p>Сookie, которые хранятся на Вашем компьютере и не удаляются при закрытии браузера. Постоянные Сookie могут сохранять пользовательские настройки для определенного веб-сайта, позволяя использовать эти предпочтения в будущих сеансах просмотра.</p>
<p>Такие Cookie позволяют идентифицировать Вас как уникального пользователя Сайта, и при возвращении на Сайт помогают &laquo;вспомнить&raquo; информацию о Вас и ранее совершенных Вами действиях.</p>
<p><strong>Аналитические</strong></p>
<p>Включают в себя информацию о том, как Вы используете Сайт. Например, какие страницы Сайта Вы посещаете, по каким ссылкам переходите и пр. Главная цель таких Cookie &mdash; улучшение функций и работы Сайта. Позволяют показывать пользователям Сайта релевантные предложения для улучшения пользовательского опыта.</p>
<p>На Сайте используются как собственные аналитические/метрические сервисы для сбора Cookie, так и сторонние метрические сервисы, такие как Яндекс.Метрика.</p>
<p><strong>Обязательные</strong></p>
<p>Минимальный набор Cookie, использование которых необходимо для корректной работы Сайта.</p>
<p><strong>Отказ от сбора и обработки Cookie-файлов</strong></p>
<p>Вы можете отказаться от обработки ООО &laquo;Тестограф&raquo; Cookie-файлов в настройках Вашего браузера. В таком случае Сайт будет использовать только обязательные Cookie-файлы, необходимые для надлежащего функционирования Сайта.</p>
</div>`;
