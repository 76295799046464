import { SuccessMark } from '@webapp/ui/lib/icons';

import css from './connection-steps.css';

export const ConnectionSteps: FC<{
    title: string;
}> = ({ title = 'Тарифы' }) => (
    <div className={css.connectionSteps}>
        <h1>{title}</h1>
        <h2>Порядок подключения</h2>
        <ul className={css.steps}>
            <li className={css.step}>
                <SuccessMark />
                <p>
                    Зарегистрироваться на{' '}
                    <a href='/' title='testograf'>
                        www.testograf.ru
                    </a>
                </p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Выбрать тариф</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>
                    Определиться с количеством необходимых пользователей (совместная работа сотрудников в 1 личном
                    кабинете) или лицензий (работа сотрудников в отдельных личных кабинетах){' '}
                </p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Ознакомиться с Договором-офертой</p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>
                    Выставить счет (самостоятельно в вашем личном кабинете или выслать реквизиты и информацию о
                    выбранном тарифе и количестве пользователей на{' '}
                    <a href='mailto:info@testograf.ru'>info@testograf.ru</a>)
                </p>
            </li>
            <li className={css.step}>
                <SuccessMark />
                <p>Оплатить счет онлайн в личном кабинете или банковским переводом</p>
            </li>
        </ul>
    </div>
);
