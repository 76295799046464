import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProfile } from 'resources/profile/selectors';
import { AccountRoutes } from 'resources/routes';
import { getSuperAdminUsers } from 'resources/users';

import { useAction } from '@webapp/common/hooks/use-actions';
import { IGNORE_CLICK_OUTSIDE } from '@webapp/common/hooks/use-click-outside';
import { getPrevUserToken } from '@webapp/common/lib/cookies';
import { ArrowDown, ProfileBtn } from '@webapp/ui/lib/icons';

import { SuperAdminModal } from './super-admin-modal';

import css from './header.css';

export const HeaderAuthMenuAccount = () => {
    const [showSuperAdminUsers, setShowSuperAdminUsers] = useState<boolean>(false);

    const profile = useSelector(selectProfile);
    const isSuperAdmin = profile.superadmin;
    const isSuperAdminUser = profile.superadminUser;
    const prevToken = getPrevUserToken();
    const hasPreviousToken = Boolean(isSuperAdminUser && prevToken);

    const getSuperAdminUsersRequest = useAction(getSuperAdminUsers);

    useEffect(() => {
        if (isSuperAdmin) {
            getSuperAdminUsersRequest();
        }
    }, [isSuperAdmin]);

    return (
        <div className={css.auth}>
            {isSuperAdmin || isSuperAdminUser ? (
                <>
                    <div className={cn(css.link, css.account, IGNORE_CLICK_OUTSIDE)}>
                        <a href={AccountRoutes.account.link}>
                            <ProfileBtn className={css.icon} />
                        </a>
                        <div className={css.accountName} onClick={() => setShowSuperAdminUsers(!showSuperAdminUsers)}>
                            {isSuperAdminUser ? profile?.email : profile?.nikname}
                            {(hasPreviousToken || !isSuperAdminUser) && (
                                <ArrowDown className={cn(css.arrowIcon, showSuperAdminUsers && css.rotatePlus180)} />
                            )}
                        </div>
                    </div>
                    {showSuperAdminUsers && (
                        <SuperAdminModal
                            nikname={profile.nikname}
                            email={profile.email}
                            onClose={() => setShowSuperAdminUsers(false)}
                            showPrevUser={hasPreviousToken}
                            prevToken={prevToken}
                        />
                    )}
                </>
            ) : (
                <a className={cn(css.link, css.account)} href={AccountRoutes.account.link}>
                    <ProfileBtn className={css.icon} />
                    <div>Личный кабинет</div>
                </a>
            )}
        </div>
    );
};

export const HeaderAuthMenuLanding = () => (
    <div className={css.auth}>
        <a className={cn(css.link, css.account)} href={AccountRoutes.account.link}>
            <ProfileBtn className={css.icon} />
            <div>Личный кабинет</div>
        </a>
    </div>
);
