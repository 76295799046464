import { useCallback } from 'react';

import { createFileUrl } from '@webapp/common/lib/utils';

import { Button } from '../button';

import css from './download-button.css';

export const DownloadButton: FC<{
    file: string;
}> = ({ file }) => {
    const url = createFileUrl(file);
    const download = useCallback(
        (e) => {
            e.preventDefault();
            window.open(url, '_blank');
        },
        [url]
    );

    return (
        <div className={css.download}>
            <Button onClick={download}>Скачать</Button>
        </div>
    );
};
