import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class FileUploadApi extends QuestionSimpleApi {
    public change(question: ISurveyQuestionModel, response: ISurveyQuestionResponseModel, value: PrimitiveValue): void {
        response.setValue(value);
        question.validate();
    }

    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ file: { id, name, path } }) => ({
                file: id,
                text: name,
                longtext: path
            })),
            comment: commentText
        };
    }

    public validate({ answers, params: { fileAmount, required } }: ISurveyQuestionModel): boolean {
        if (!required) {
            return true;
        }

        const uploadedCount = answers.reduce((acc, { file: { path } }) => ((acc += !!path ? 1 : 0), acc), 0);

        return answers.length > 0 && uploadedCount <= fileAmount;
    }
}
