import cloneDeep from 'lodash/cloneDeep';
import { createContext } from 'react';

import { initialView } from '@webapp/account/src/resources/survey/addons/view';

export interface SelectCustomStylesConfig {
    root?: CSSProperties;
    item?: CSSProperties;
    size?: string;
    activeItem?: CSSProperties;
    arrow?: CSSProperties;
    label?: CSSProperties;
    dropdownBg?: CSSProperties;
}

export interface CustomStylesConfig {
    fieldBorderColor: string;
    lineHeight: number;
    gap: number;
    textField: CSSProperties;
    select: SelectCustomStylesConfig;
    survey: CSSProperties;
    question: {
        title: CSSProperties;
        text: CSSProperties;
    };
    checkbox: {
        root?: CSSProperties;
        checked?: CSSProperties;
        label?: CSSProperties;
    };
    star: {
        root?: CSSProperties;
    };
    icon: {
        root?: CSSProperties;
    };
    imageSlider: {
        root?: CSSProperties;
    };
    selectImage: {
        resizer?: CSSProperties;
    };
    button: {
        root?: CSSProperties;
    };
    text?: CSSProperties;
    matrix: {
        fixedHeader?: CSSProperties;
    };
    datePicker: {
        root?: CSSProperties;
        arrow?: CSSProperties;
    };
}

export const customStylesInitial: CustomStylesConfig = {
    fieldBorderColor: 'rgba(0,0,0,0)',
    lineHeight: initialView.lineHeight,
    gap: initialView.gap,
    textField: {},
    survey: {},
    question: {
        title: {},
        text: {}
    },
    select: {
        item: {}
    },
    checkbox: {
        checked: {},
        root: {},
        label: {}
    },
    star: {},
    text: {
        fontSize: '16'
    },
    icon: {},
    imageSlider: {
        root: {
            color: undefined
        }
    },
    button: {},
    selectImage: {
        resizer: {}
    },
    matrix: {},
    datePicker: {}
};

// TODO review Provider usage, in some cases maybe not needed
export const CustomStylesCtx = createContext<Partial<CustomStylesConfig>>(cloneDeep(customStylesInitial));
