import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';

import { CANTRATE_ID } from '../constants';
import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

const checkValue = (val: any) => (typeof val === 'number' ? true : Boolean(val));

export class CsiApi extends QuestionSimpleApi {
    // TODO: correct matching @didolf
    public query({ answers, id, groups }: ISurveyQuestionModel): AnswersRequest {
        const groupWithOrderOne = groups[0];
        const groupWithOrderTwo = groups[1];

        return {
            question: id,
            data: answers.flatMap(({ id: answerId, response: { value: score, extra: importance, comment } }) => {
                if (score === CANTRATE_ID) {
                    return [
                        {
                            answer: answerId,
                            bool: true,
                            comment: null,
                            cantRate: true
                        }
                    ];
                }

                return [
                    {
                        group: groupWithOrderOne.id,
                        bool: true,
                        answer: answerId,
                        int: score as number,
                        comment,
                        cantRate: undefined
                    },
                    {
                        group: groupWithOrderTwo.id,
                        bool: true,
                        answer: answerId,
                        int: importance as number,
                        comment,
                        cantRate: undefined
                    }
                ];
            })
        };
    }

    public validate({ answers, params: { required, commentRequired } }: ISurveyQuestionModel): boolean {
        if (required) {
            answers.forEach((a) =>
                a.setValid(
                    checkValue(a.response.value) &&
                        checkValue(a.response.extra) &&
                        (commentRequired ? checkValue(a.response.comment) : true)
                )
            );

            return answers.every(({ response: { value, extra, comment } }) =>
                value === CANTRATE_ID
                    ? true
                    : checkValue(value) && checkValue(extra) && (commentRequired ? checkValue(comment) : true)
            );
        }

        return true;
    }
}
