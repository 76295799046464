import { AlignSetting } from '@webapp/common/lib/ui';

export interface View {
    lineHeight: number;
    gap: number;
    questionBlockPosition: AlignSetting;
    buttonBlockPosition: AlignSetting;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    paddingBottom: number;
}

export const initialView: Readonly<View> = {
    lineHeight: 2,
    gap: 3,
    questionBlockPosition: AlignSetting.LEFT,
    buttonBlockPosition: AlignSetting.LEFT,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    paddingBottom: 5
};
