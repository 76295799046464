import { customStyleHTML } from 'components/ru/docs/html/custom-style';

import { Doc } from './doc';

export const CustomCss: FC = () => (
    <Doc
        html={customStyleHTML}
        file='Пользовательские стили для опросов 04.07.2023.docx'
        subTitle={null}
        title='Пользовательские стили для опросов'
    />
);
