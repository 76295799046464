import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import { formatPhone } from '@webapp/ui/lib/textfield/lib';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class PhoneApi extends QuestionSimpleApi {
    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { extra, value } }) => ({
                answer: parseInt(String(id)),
                text: String(value),
                longtext: extra as string,
                bool: true
            })),
            comment: commentText
        };
    }

    public validate({ answers, params }: ISurveyQuestionModel): boolean {
        const { phoneFormatCountry, phoneFormatNumber, required } = params;
        const mask = (phoneFormatCountry + ' ' + phoneFormatNumber).replace(/_/g, '');
        const maskLen = mask.length;

        if (!required) {
            return true;
        }

        const withValueCount = answers.reduce((acc, { response: { extra, value } }) => {
            const fullValue = (extra as string) + ' ' + (value as string);
            const formatted = formatPhone(fullValue || '', phoneFormatCountry, phoneFormatNumber);
            const match = formatted.length === maskLen;

            return acc + Number(match);
        }, 0);

        return withValueCount === answers.length;
    }
}
