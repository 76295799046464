import dynamic from 'next/dynamic';
import { options } from 'preact';

import 'swagger-ui-react/swagger-ui.css';

import { DownloadButton } from '@webapp/landing/src/components/ui/download-button';

import css from './doc/doc.css';

const SwaggerUI = dynamic(() => import('swagger-ui-react'), {
    ssr: false
});

// https://github.com/preactjs/preact/issues/3798
// eslint-disable-next-line @typescript-eslint/unbound-method
const oldVNode = options.vnode;
options.vnode = (vn) => {
    const { props, type } = vn;
    if (
        type !== null &&
        props &&
        typeof props.children === 'object' &&
        props.children !== null &&
        props.children.constructor !== undefined &&
        // @ts-ignore
        typeof props.children.toArray === 'function'
    ) {
        // @ts-ignore
        props.children = props.children.toArray();
    }

    if (props && Array.isArray(props.children)) {
        props.children = props.children.map((c) => (c && typeof c.toArray === 'function' ? c.toArray() : c));
    }

    if (oldVNode) oldVNode(vn);
};

export const PublicApi: FC = () => (
    <div className={css.offer}>
        <h1>Документация API</h1>
        <DownloadButton file='testograf-ClientsApi-v1.4-resolved.yaml' />
        <div suppressHydrationWarning className={css.content}>
            {/* @ts-ignore */}
            <SwaggerUI url='/files/testograf-ClientsApi-v1.4-resolved.yaml' />
        </div>
    </div>
);
