import cn from 'classnames';

import { currentYear } from '@webapp/common/lib/date';

import css from './footer.css';

export const Footer: FC = () => (
    <footer className={cn(css.footer)}>
        <div className={css.container}>
            <div className={css.contacts}>
                <div className={cn(css.contact, css.tel)}>
                    <a className={css.link} href='tel:+74951206519'>
                        +7 (495) 120-65-19
                    </a>
                </div>
                <div className={cn(css.contact, css.email)}>
                    <a className={css.link} href='mailto:info@testograf.ru'>
                        info@testograf.ru
                    </a>
                </div>
            </div>
            <div className={css.copy}>2014-{currentYear} права защищены</div>
        </div>
    </footer>
);
