import type { Return } from 'react-cool-onclickoutside';
import useOnClickOutside from 'react-cool-onclickoutside';

import { nop } from '../lib/utils';

// TODO return tuple [ref, cbFn]

export const IGNORE_CLICK_OUTSIDE = 'ignoreClickOutside';

export const useClickOutside = (onClick: () => void, enabled = true): Return => {
    const ref = useOnClickOutside(onClick, {
        ignoreClass: ['popperPortal', 'ReactModalPortal', 'fileManager', IGNORE_CLICK_OUTSIDE]
    });
    return enabled ? ref : nop;
};
