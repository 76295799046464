import cn from 'classnames';
import { AccountRoutes } from 'resources/routes';

import { useAuthInfoConnect } from '@webapp/common/hooks/use-auth-info-connect';

import { HeaderAuthMenuAccount, HeaderAuthMenuLanding } from './header-auth-menu';

import css from './header.css';

export const HeaderMenu: FC<{
    isLanding: boolean;
    hideNoAuth?: boolean;
    logout?: () => void;
}> = ({ isLanding, hideNoAuth, logout }) => {
    const { isLoggedIn } = useAuthInfoConnect(logout);

    const renderAuthMenu = () => {
        return isLanding ? <HeaderAuthMenuLanding /> : <HeaderAuthMenuAccount />;
    };

    return (
        <div className={cn(css.item, css.mobileShow)}>
            {isLoggedIn
                ? renderAuthMenu()
                : !hideNoAuth && (
                      <div className={css.nonAuth}>
                          <a className={cn(css.link, css.login)} href={AccountRoutes.authLogin.link}>
                              Вход
                          </a>
                          <div className={css.span} />
                          <a className={cn(css.link, css.register)} href={AccountRoutes.authRegister.link}>
                              Регистрация
                          </a>
                      </div>
                  )}
        </div>
    );
};
