import cn from 'classnames';
import { useCallback } from 'react';

import { useClickOutside } from '@webapp/common/hooks/use-click-outside';
import { useToggle } from '@webapp/common/hooks/use-toggle';
import { ColorScheme, stopEvent } from '@webapp/common/lib/ui';
import { Burger, LogoDark, LogoLight } from '@webapp/ui/lib/icons';
import { Nav } from '@webapp/ui/lib/nav';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import { HeaderMenu } from './header-menu';

import css from './header.css';

export const Header: FC<{
    className?: string;
    mode: ColorScheme;
    logoLink: string;
    logout?: () => void;
    hideNoAuth?: boolean;
    isLanding?: boolean;
    page?: 'main';
}> = ({ className, hideNoAuth, logoLink, logout, mode, isLanding, page }) => {
    const { enabled: navActive, off: hideNav, toggle: toggleNav } = useToggle(false);
    const Logo = page === 'main' ? LogoLight : LogoDark;

    const handleClickBurger = useCallback(
        (e: ReactMouseEvent): void => {
            stopEvent(e);
            toggleNav();
        },
        [toggleNav]
    );

    const outsideRef = useClickOutside(hideNav);

    const elAccount = <HeaderMenu isLanding={isLanding} hideNoAuth={hideNoAuth} logout={logout} />;

    return (
        <div className={CssUiComponent.NO_PRINT} ref={outsideRef}>
            <Nav active={navActive} onClose={handleClickBurger} />
            <header
                id='header'
                className={cn(
                    css.header,
                    { [css.dark]: mode === ColorScheme.DARK, [css.light]: mode === ColorScheme.LIGHT },
                    className
                )}
            >
                <div className={css.container}>
                    <div className={css.logo}>
                        <a href={logoLink} title='testograf'>
                            <Logo />
                        </a>
                    </div>
                    {isLanding && (
                        <div className={cn(css.menu, css.menuLanding)}>
                            <div className={css.item}>
                                <a className={css.link} href='/ru/capabilities-hr'>
                                    Возможности
                                </a>
                            </div>
                            <div className={css.item}>
                                <a className={css.link} href='/ru/pricing-plans'>
                                    Тарифы
                                </a>
                            </div>
                            {elAccount}
                        </div>
                    )}
                    <div className={css.menu}>
                        {!isLanding && elAccount}
                        <div className={css.burger}>
                            <Burger className={css.btn} onClick={handleClickBurger} />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};
