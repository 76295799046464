import cn from 'classnames';
import { useSelector } from 'react-redux';
import { AccountRoutes } from 'resources/routes';
import { selectSuperAdminUsers } from 'resources/users/selectors';

import { useClickOutside } from '@webapp/common/hooks/use-click-outside';
import { delUserToken, setUserToken } from '@webapp/common/lib/cookies';
import { LocalStorage } from '@webapp/common/lib/storage';
import { ProfileBtn } from '@webapp/ui/lib/icons';

import css from './super-admin-modal.css';

export const SuperAdminModal: FC<{
    nikname: string;
    email: string;
    onClose: () => void;
    showPrevUser?: boolean;
    prevToken?: string;
}> = ({ nikname, email, onClose, showPrevUser, prevToken }) => {
    const outsideRef = useClickOutside(onClose);
    const superAdminUsers = useSelector(selectSuperAdminUsers);
    const superAdminData: { nikname: string; email: string } = LocalStorage.get('superadmin');

    return (
        <div className={css.wrap} ref={outsideRef}>
            <a className={cn(css.header, css.account)} href={AccountRoutes.account.link}>
                <ProfileBtn className={css.icon} />
                <div className={css.info}>
                    <div className={css.name}>{nikname}</div>
                    <div className={css.mail}>{email}</div>
                </div>
            </a>
            <div className={css.container}>
                {superAdminUsers.map((user, index) => {
                    return (
                        <a
                            href={user.directLoginLink}
                            className={css.account}
                            key={index}
                            onClick={() => {
                                LocalStorage.set('superadmin', { nikname, email });
                            }}
                        >
                            <div className={css.accountName}>{user.name}</div>
                            <div className={css.accountMail}>{user.email}</div>
                        </a>
                    );
                })}
                {showPrevUser && (
                    <span
                        className={css.account}
                        onClick={() => {
                            setUserToken(prevToken);
                            delUserToken(true);
                            window.location.reload();
                        }}
                    >
                        {superAdminData ? (
                            <>
                                <div className={css.accountName}>{superAdminData.nikname}</div>
                                <div className={css.accountMail}>{superAdminData.email}</div>
                            </>
                        ) : (
                            <div className={css.accountName}>Вернуться в основной аккаунт</div>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};
