import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { MatrixApi } from './common/matrix';

export class MatrixDropdownListApi extends MatrixApi {
    public change(
        { answers, groups }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(value);
        answers.forEach((answer) => {
            if (answer.valid) {
                return;
            }
            let isAnswered = 0;
            groups.forEach((group) => {
                if (group.responses.get(answer.id.toString())?.response.value) {
                    isAnswered += 1;
                }
            });
            if (isAnswered !== groups.length) {
                answer.setValid(true);
            }
        });
    }

    public changeExtra(
        question: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(!!value);
        super.changeExtra(question, response, value);
    }

    public query = ({ commentText, groups, id }: ISurveyQuestionModel): AnswersRequest => ({
        question: id,
        data: groups.flatMap(({ id: group, responses }) =>
            Object.entries(responses.toJSON()).map(
                ([
                    answerId,
                    {
                        response: { extra, value }
                    }
                ]) => ({
                    group,
                    bool: true,
                    answer: Number(answerId),
                    text: (extra as string) || (value as string)
                })
            )
        ),
        comment: commentText
    });
}
