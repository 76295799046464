export const customStyleHTML = `<div class="docHTML">
<p>Мы не рекомендуем завязываться на структуру элементов, она может измениться в будущем. В случае если вам не хватает предоставленных системных классов напишите на info@testograf.ru с темой CSS.</p>
<h3>Классы блоков страницы</h3>
<table>
    <tbody>
        <tr>
            <td valign="middle">
                <p><strong>Тип</strong></p>
            </td>
            <td valign="middle">
                <p><strong>основной</strong></p>
            </td>
            <td valign="middle">
                <p><strong>вложенные</strong></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>вопрос</p>
            </td>
            <td valign="middle">
                <p>question</p>
                <p><br></p>
                <p>question_{id} - конкретный вопрос</p>
            </td>
            <td valign="middle">
                <p>header</p>
                <p>order</p>
                <p>title</p>
                <p>timer</p>
                <p>help - текст подсказки</p>
                <p>comment - пользовательский комментарий</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>фоновое изображение</p>
            </td>
            <td valign="middle">
                <p>backgroundImage</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Дополнительная обертка контента для встроенных опросов</p>
            </td>
            <td valign="middle">
                <p>survey__embedded-content-wrap</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>блок вопросов</p>
            </td>
            <td valign="middle">
                <p>surveyInner</p>
            </td>
            <td valign="middle">
                <p>content - внутренний блок с учетом настроек отступов</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>шапка</p>
            </td>
            <td valign="middle">
                <p>header</p>
            </td>
            <td valign="middle">
                <p>titleContainer</p>
                <p>title</p>
                <p>logoContainer</p>
                <p>logo</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>горизонтальный блок</p>
            </td>
            <td valign="middle">
                <p>section</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>начало</p>
            </td>
            <td valign="middle">
                <p>start</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>приветственный текст</p>
            </td>
            <td valign="middle">
                <p>welcome</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>контейнер навигационных кнопок</p>
            </td>
            <td valign="middle">
                <p>navigation</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
    </tbody>
</table>
<p><br></p>
<h3>Классы для типов вопросов</h3>
<table>
    <tbody>
        <tr>
            <td valign="middle">
                <p><strong>Тип</strong></p>
            </td>
            <td valign="middle">
                <p><strong>основной</strong></p>
            </td>
            <td valign="middle">
                <p><strong>вложенные</strong></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>*</p>
            </td>
            <td valign="middle">
                <p>question</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Пользовательское соглашение</p>
            </td>
            <td valign="middle">
                <p>agreement</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>modal</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Дата-время</p>
            </td>
            <td valign="middle">
                <p>datetime</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Выпадающий список</p>
            </td>
            <td valign="middle">
                <p>dropdown</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Загрузка файлов</p>
            </td>
            <td valign="middle">
                <p>fileUpload</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>email</p>
            </td>
            <td valign="middle">
                <p>email</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>inputWrap</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Группа свободных ответов</p>
            </td>
            <td valign="middle">
                <p>groupFreeAnswers</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>invalid</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Выбор одного, выбор нескольких, тесты</p>
            </td>
            <td valign="middle">
                <p>listSelect</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>checkbox</p>
                <p>inputWrap</p>
                <p>invalid</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Длинный текст</p>
            </td>
            <td valign="middle">
                <p>longText</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Текстовая матрица</p>
            </td>
            <td valign="middle">
                <p>matrixText</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>cell</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Матрица выпадающий</p>
            </td>
            <td valign="middle">
                <p>matrixDropdown</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>cell</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Матрица выбор одного или нескольких</p>
            </td>
            <td valign="middle">
                <p>matrixListSelect</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>cell</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Звездная матрица</p>
            </td>
            <td valign="middle">
                <p>starsMatrix</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>ФИО</p>
            </td>
            <td valign="middle">
                <p>name</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>cell</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>NPS</p>
            </td>
            <td valign="middle">
                <p>nps</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Число</p>
            </td>
            <td valign="middle">
                <p>number</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Телефон</p>
            </td>
            <td valign="middle">
                <p>phone</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Ранжирование</p>
            </td>
            <td valign="middle">
                <p>rank</p>
            </td>
            <td valign="middle">
                <p>answer</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Место жительства</p>
            </td>
            <td valign="middle">
                <p>residence</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Шкала</p>
            </td>
            <td valign="middle">
                <p>scale</p>
            </td>
            <td valign="middle">
                <p>label</p>
                <p>answer</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Распределительная шкала</p>
            </td>
            <td valign="middle">
                <p>scaleDistribute</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
                <p>slider</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Короткий текст</p>
            </td>
            <td valign="middle">
                <p>shortText</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Смайлы</p>
            </td>
            <td valign="middle">
                <p>smiles</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Звездный рейтинг</p>
            </td>
            <td valign="middle">
                <p>stars</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Семантический дифференциал</p>
            </td>
            <td valign="middle">
                <p>semDiff</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Текст между вопросами</p>
            </td>
            <td valign="middle">
                <p>textBlock</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>Выбор изображения</p>
            </td>
            <td valign="middle">
                <p>imageSelect</p>
            </td>
            <td valign="middle">
                <p>answer</p>
                <p>label</p>
            </td>
        </tr>
    </tbody>
</table>
<p><br></p>
<h3>Классы визуальных элементов</h3>
<p>Общие</p>
<table>
    <tbody>
        <tr>
            <td valign="middle">
                <p><strong>тип</strong></p>
            </td>
            <td valign="middle">
                <p><strong>основной</strong></p>
            </td>
            <td valign="middle">
                <p><strong>вложенные</strong></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>обязательное поле</p>
            </td>
            <td valign="middle">
                <p>required</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>невалидные данные</p>
            </td>
            <td valign="middle">
                <p>invalid</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>область прокрутки</p>
            </td>
            <td valign="middle">
                <p>scroll</p>
            </td>
            <td valign="middle">
                <p>content</p>
                <p>vbar</p>
                <p>hbar</p>
                <p>thumb</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>выпадающий список</p>
            </td>
            <td valign="middle">
                <p>select</p>
            </td>
            <td valign="middle">
                <p>options</p>
                <p>option</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>поле ввода</p>
            </td>
            <td valign="middle">
                <p>input</p>
            </td>
            <td valign="middle">
                <p>&lt;input&gt;</p>
                <p>&lt;textarea&gt;</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>списки элементов</p>
            </td>
            <td valign="middle">
                <p>list</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>html-содержимое</p>
            </td>
            <td valign="middle">
                <p>richText</p>
            </td>
            <td valign="middle">
                <p>&lt;p&gt;</p>
                <p>&lt;h1&gt;, &lt;h2&gt;, &lt;h3&gt;</p>
                <p>&lt;picture&gt;</p>
                <p><br></p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>checkbox</p>
            </td>
            <td valign="middle">
                <p>checkbox</p>
            </td>
            <td valign="middle">
                <p>checkboxMark</p>
                <p>checkboxSelected</p>
            </td>
        </tr>
        <tr>
            <td valign="middle">
                <p>элементы исключенные при печати страницы</p>
            </td>
            <td valign="middle">
                <p>noPrint</p>
            </td>
            <td valign="middle">
                <p><br></p>
            </td>
        </tr>
    </tbody>
</table>
<p><br></p>
<h3 style="margin-bottom: 20px"><strong>Примеры загрузки кастомных шрифтов</strong> </h3>
<p style="margin-bottom: 20px">
@font-face {
<br>
font-family: 'CustomFont';
<br>
src: url('https://core.testograf.ru/files/answers/1050001/Fonts/611c806cd685e74938355a8d8ff528d9.ttf') format('truetype');
<br>
font-weight: normal;
<br>
font-style: italic;
<br>
}
</p>
<p style="margin-bottom: 20px">
@font-face {
<br>
font-family: 'CustomFont';
<br>
src: url('https://core.testograf.ru/files/answers/1050001/Fonts/bca4d170a4b3408c51afe5dcdbc4337c.ttf') format('truetype');
<br>
font-weight: 700;
<br>
font-style: normal;
<br>
}
</p>
<p style="margin-bottom: 20px">
@font-face {
<br>
font-family: 'CustomFont';
<br>
src: url('https://core.testograf.ru/files/answers/1050001/Fonts/6dd6982ba61f26e309357a572e25a898.ttf') format('truetype');
 <br>
 font-weight: normal;
 <br>
 font-style: normal;
 <br>
}
</p>
</div>
`;
