import cn from 'classnames';

import { LoaderInline } from '../icons';

import css from './loader.css';

export const Loader: FC<{
    className?: string;
    inline?: boolean;
    small?: boolean;
    visible?: boolean;
}> = ({ className, inline, small, visible }) => {
    return (
        <div
            className={cn(
                css.loader,
                {
                    [css.inline]: inline,
                    [css.small]: small
                },
                visible ? css.visible : css.hidden,
                className
            )}
        >
            <LoaderInline />
        </div>
    );
};
