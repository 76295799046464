import { nanoid } from 'nanoid';

import { HOST_DOMAIN } from '../conf';

import { isBrowser, isServer } from './const';
import { LocalStorage, LocalStorageKeys } from './storage';

export enum ApiRespCode {
    OK_CODE = 0,
    ERR_FORMAT_CODE = 1,
    ERR_AUTH_CODE = 2,
    ERR_EXEC_CODE = 3,
    ERR_SVR_CODE = 4,
    ERR_REG_CODE = 5,
    ERR_STATUS_CODE = 6, // CONFIRM_EMAIL
    ERR_NOUSER_CODE = 7,
    ERR_TOKEN_CODE = 8,
    ERR_CONFIRM_CODE = 9,
    ERR_RESET_CODE = 10,
    ERR_RESEND_CODE = 11,
    ERR_REFRESH_CODE = 12,
    ERR_FILE_CODE = 13,
    ERR_NOTFOUND_CODE = 14,
    ERR_SEND_CODE = 15,
    ERR_REWR_CODE = 16,
    ERR_VK_AUTH_CODE = 17,
    ERR_IP_UNIQ_CODE = 18,
    ERR_TOO_MANY_CODE = 19,
    ERR_TOO_MANY_FOR_DATE_CODE = 20,
    ERR_REFILL_CODE = 21,
    ERR_DEMO_LICENSE_CODE = 22,
    ERR_BAD_TOKEN = 23,
    ERR_TOO_MANY_ATTEMPTS_CODE = 24,
    ERR_TIMER_OFF = 25,
    ERR_404 = 26,
    ERR_NOT_FOUND_FILES = 27,
    ERR_CHANGE_EMAIL_LIST = 28,
    ERR_SHARE = 29,
    ERR_API_TOKEN = 30,
    ERR_COUNT_REQUEST = 31,
    ERR_COUNT_SUBUSERS = 32,
    ERR_STATUS_SUBUSERS = 33,
    ERR_DEVICE_LINK = 34,
    ERR_DEVICE_KEY = 35,
    ERR_DELETED_PAGE = 36,
    ERR_BAD_EMAIL = 37,
    ERR_DUP_EMAIL = 38,
    ERR_VOLUME_LIMIT = 39,
    ERR_END_LICENSE = 40,
    ERR_END_SURVEY = 41,
    ERR_USED_DEVICE_LINK = 42,
    ERR_BAD_DEVICE_LINK = 43,
    ERR_DELETED_RESPONSE = 44,
    ERR_ANSWERED_RESPONSE = 45,
    ERR_FILE_TYPE = 46,
    SURVEY_COMPLETE = 70,
    SURVEY_FILLED = 77,
    ERR_API_KEY = 86
}

export const isSubdomain = ((): boolean => {
    if (isServer) return false;

    const [sub] = isBrowser && window.location.hostname.split(`.${HOST_DOMAIN}`);

    return !!sub && sub !== 'www' && sub !== HOST_DOMAIN;
})();

const urlRe = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
export const isUrl = (url: string): boolean => urlRe.test(url);

export const createFingerprint = (): string => nanoid();

export const storeFingerprint = (): string => {
    const fp = createFingerprint();
    LocalStorage.set<string>(LocalStorageKeys.DEVICE_FINGERPRINT, fp);
    return fp;
};

export const getFingerprint = (): string => LocalStorage.get<string>(LocalStorageKeys.DEVICE_FINGERPRINT);

if (isBrowser && !getFingerprint()) {
    storeFingerprint();
}

export enum FetchStatus {
    PENDING = 'pending',
    DONE = 'done',
    ERROR = 'error',
    NOT_FOUND = 'NOT_FOUND'
}

export enum CheckPasswordStatus {
    PENDING = 'pending',
    DONE = 'done',
    ERROR = 'error',
    PASSWORD_ALREADY_USED = 'passwordWasAlreadyUsed'
}
