import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import { CANTRATE_ID } from '../constants';
import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class SmileApi extends QuestionSimpleApi {
    public change(
        { answers }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(value);

        answers.map((answer) => {
            if (answer.id !== response.answer.id) {
                answer.response.setValue(false);
            }
        });
    }

    public query({ answers, commentText: comment, id: question }: ISurveyQuestionModel): AnswersRequest {
        const isCantRate = answers?.[0]?.response.value === CANTRATE_ID;
        const data = isCantRate
            ? [{ cantRate: true, bool: true, comment }]
            : answers
                  .filter(({ response: { value } }) => value)
                  .map(({ id, value: [value1] }) => ({
                      answer: id,
                      text: value1 as string,
                      bool: true
                  }));

        return {
            question,
            data,
            comment
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            return answers.some(({ response: { value } }) => !!value);
        }

        return true;
    }
}
