import { generatePath } from 'react-router-dom';

import { HOST_MAIN, HOST_PROTOCOL } from '@webapp/common/conf';
import { isDev } from '@webapp/common/lib/const';
import { getUserToken } from '@webapp/common/lib/cookies';
import { createSubdomainUrl } from '@webapp/common/lib/utils';

import { history } from 'resources/history';

export interface RouteDescription {
    link: string;
    create?: (...args: any) => string;
    go?: (...args: any) => void;
}

// TODO use only generic link. generate go, create, root

// TODO fix typing
export const AccountRoutes /*: Readonly<Record<string, RouteDescription>>*/ = {
    account: {
        link: '/account',
        assign: function () {
            window.location.assign(this.link);
        }
    },
    accountStats: {
        link: '/account/stats'
    },
    users: {
        link: '/account/users'
    },
    access: {
        link: '/account/access'
    },
    accessByFolders: {
        link: '/account/access/folders'
    },
    accessByUsers: {
        link: '/account/access/users'
    },
    auth: {
        link: '/auth',
        root: '/auth/'
    },
    authLogin: {
        link: '/auth/login',
        replace: function (): void {
            history.replace(this.link);
        },
        assign: function (): void {
            window.location.assign(this.link);
        }
    },
    authConfirmDeviceRequest: {
        link: '/auth/confirm-device-request'
    },
    authConfirmDeviceLink: {
        link: '/auth/confirm-device'
    },
    authLogout: {
        link: '/auth/logout'
    },
    authRegister: {
        link: '/auth/register'
    },
    authResetPassword: {
        link: '/auth/reset-password'
    },
    authChangePassword: {
        link: '/auth/change-password'
    },
    authConfirmEmail: {
        link: '/auth/confirm-email'
    },
    authDirect: {
        link: '/auth/direct-login'
    },
    folder: {
        link: '/account/folder/:id',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    getFile: {
        link: '/account/getFile/:id',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    copyTemplate: {
        link: '/account/copy-template/:id',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    results: {
        link: '/account/survey/:id/results',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: string | number): void {
            history.push(this.create(id));
        }
    },
    resultsCommon: {
        link: '/account/survey/:id/results/common',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    resultsShare: {
        link: '/account/survey/:id/results/share',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    resultsSingle: {
        link: '/account/survey/:id/results/single',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    resultsAnswer: {
        link: '/account/survey/:id/results/single/:answer_id'
    },
    preview: {
        link: '/preview/:id',
        root: '/preview',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    share: {
        link: '/account/survey/:id/share'
    },
    survey: {
        link: '/account/survey/:id',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: string | number): void {
            history.push(this.create(id));
        }
    },
    surveyCreate: {
        link: '/account/survey/create',
        go: function (params = ''): void {
            history.push(this.link + params);
        }
    },
    surveyEdit: {
        link: '/account/survey/:id/edit',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: string | number): void {
            history.push(this.create(id));
        }
    },
    surveyEditCreate: {
        link: '/account/survey/:id/edit/create/:pageId?',
        create: function (id: number | string, pageId?: number | string): string {
            return generatePath(this.link, { id, pageId });
        },
        go: function (id: string | number, pageId?: string | number): void {
            history.push(this.create(id, pageId));
        }
    },
    surveyEditLogicAndQuotas: {
        link: '/account/survey/:id/edit/logic-and-quotas',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyEditLogic: {
        link: '/account/survey/:id/edit/logic-and-quotas/logic',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyEditQuota: {
        link: '/account/survey/:id/edit/logic-and-quotas/quota',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyEditSettings: {
        link: '/account/survey/:id/edit/settings',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyEditDesign: {
        link: '/account/survey/:id/edit/design',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyEditMain: {
        link: '/account/survey/:id/edit/settings/main'
    },
    surveyEditParam: {
        link: '/account/survey/:id/edit/settings/params'
    },
    surveyEditTexts: {
        link: '/account/survey/:id/edit/settings/texts'
    },
    surveyEditTests: {
        link: '/account/survey/:id/edit/settings/tests',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    notify: {
        link: '/account/survey/:id/notify'
    },
    notifyEmail: {
        link: '/account/survey/:id/notify/email',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    notifyHooks: {
        link: '/account/survey/:id/notify/hooks',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    emailReview: {
        link: '/account/survey/:id/share/email/:mail_id/review'
    },
    smsReview: {
        link: '/account/survey/:id/share/sms/:sms_id/review'
    },
    emailStep1: {
        link: '/account/survey/:id/share/email/:mail_id/form-step1',
        create: function (id: number | string, mail_id: string | number): string {
            return generatePath(this.link, { id, mail_id });
        },
        go: function (id: number | string, mail_id: string | number): void {
            history.push(this.create(id, mail_id));
        }
    },
    smsStep1: {
        link: '/account/survey/:id/share/sms/:sms_id/form-step1',
        create: function (id: number | string, sms_id: string | number): string {
            return generatePath(this.link, { id, sms_id });
        },
        go: function (id: number | string, sms_id: string | number): void {
            history.push(this.create(id, sms_id));
        }
    },
    emailStep1Edit: {
        link: '/account/survey/:id/share/email/:mail_id/form-step1/edit',
        create: function (id: number | string, mail_id: string | number): string {
            return generatePath(this.link, { id, mail_id });
        },
        go: function (id: number | string, mail_id: string | number): void {
            history.push(this.create(id, mail_id));
        }
    },
    smsStep1Edit: {
        link: '/account/survey/:id/share/sms/:sms_id/form-step1/edit',
        create: function (id: number | string, sms_id: string | number): string {
            return generatePath(this.link, { id, sms_id });
        },
        go: function (id: number | string, sms_id: string | number): void {
            history.push(this.create(id, sms_id));
        }
    },
    emailStep1Preview: {
        link: '/account/survey/:id/share/email/:mail_id/form-step1/preview',
        create: function (id: number | string, mail_id: string | number): string {
            return generatePath(this.link, { id, mail_id });
        },
        go: function (id: number | string, mail_id: string | number): void {
            history.push(this.create(id, mail_id));
        }
    },
    smsStep1Preview: {
        link: '/account/survey/:id/share/sms/:sms_id/form-step1/preview',
        create: function (id: number | string, sms_id: string | number): string {
            return generatePath(this.link, { id, sms_id });
        },
        go: function (id: number | string, sms_id: string | number): void {
            history.push(this.create(id, sms_id));
        }
    },
    emailStep2: {
        link: '/account/survey/:id/share/email/:mail_id/form-step2',
        create: function (id: number | string, mail_id: string | number): string {
            return generatePath(this.link, { id, mail_id });
        }
    },
    smsStep2: {
        link: '/account/survey/:id/share/sms/:sms_id/form-step2',
        create: function (id: number | string, sms_id: string | number): string {
            return generatePath(this.link, { id, sms_id });
        }
    },
    emailStat: {
        link: '/account/survey/:id/share/email/statistics',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    emailStatCommon: {
        link: '/account/survey/:id/share/email/statistics/common',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    smsStatCommon: {
        link: '/account/survey/:id/share/sms/statistics/common',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    emailStatDetail: {
        link: '/account/survey/:id/share/email/statistics/detail',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    smsStatDetail: {
        link: '/account/survey/:id/share/sms/statistics/detail',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    surveyResultsSingle: {
        link: '/account/survey/:id/results/single',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareLink: {
        link: '/account/survey/:id/share/link',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: string | number): void {
            history.push(this.create(id));
        }
    },
    sharePrint: {
        link: '/account/survey/:id/share/print',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareEmail: {
        link: '/account/survey/:id/share/email',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: number | string): string {
            return history.push(this.create(id));
        }
    },
    shareSms: {
        link: '/account/survey/:id/share/sms',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: number | string): string {
            return history.push(this.create(id));
        }
    },
    offline: {
        link: '/account/survey/:id/share/offline',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareOrder: {
        link: '/account/survey/:id/share/order',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareWidget: {
        link: '/account/survey/:id/share/widget',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareLinks: {
        link: '/account/survey/:id/share/extra_links',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    shareEmbed: {
        link: '/account/survey/:id/share/embed',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    sharePopup: {
        link: '/account/survey/:id/share/popup',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    api: {
        link: '/account/api'
    },
    settings: {
        link: '/account/settings'
    },
    settingsMain: {
        link: '/account/settings/main'
    },
    settingsChangePassword: {
        link: '/account/settings/change-password'
    },
    settingsMail: {
        link: '/account/settings/mail'
    },
    settingsSms: {
        link: '/account/settings/sms'
    },
    orders: {
        link: '/account/my-orders'
    },
    ordersLicence: {
        link: '/account/my-orders/license',
        go: function (params = ''): void {
            history.push(this.link + params);
        }
    },
    ordersHistory: {
        link: '/account/my-orders/history',
        go: function (params = ''): void {
            history.push(this.link + params);
        }
    },
    ordersLicenceData: {
        link: '/account/my-orders/license/data',
        go: function (params = ''): void {
            history.push(this.link + params);
        }
    },
    ordersLicencePay: {
        link: '/account/my-orders/license/pay/:id',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        },
        go: function (id: string | number): void {
            history.push(this.create(id));
        }
    },
    ordersPaySuccess: {
        link: '/account/my-orders/license/pay/:id/success',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    },
    ordersPayFail: {
        link: '/account/my-orders/license/pay/:id/fail',
        create: function (id: number | string): string {
            return generatePath(this.link, { id });
        }
    }
};

export const accountRoutes = [
    AccountRoutes.users.link,
    AccountRoutes.access.link,
    AccountRoutes.orders.link,
    AccountRoutes.settings.link,
    AccountRoutes.accountStats.link,
    AccountRoutes.api.link
];
export const surveyRoutes = [AccountRoutes.surveyCreate.link, AccountRoutes.survey.link];

export const createDownloadLink = (id: number): string =>
    `${HOST_PROTOCOL}${isDev ? 'localhost:8080' : HOST_MAIN}/${AccountRoutes.getFile.create(id)}`;

// TODO avoid user token in url
export const createPreviewUrl = (subdomain: string, header: boolean): string =>
    `${createSubdomainUrl(subdomain)}/?preview=1&lktoken=${getUserToken()}${header ? '&header=1' : ''}`;

export {};
