import { createAsyncThunk } from '@reduxjs/toolkit';

import { wrapApiCall } from '@webapp/common/lib/sagas';

import { GET, post } from 'libs/request';

import { incUserLicence } from '../profile/actions';

import { mapUser } from './map';

export const extraUsersList = createAsyncThunk(
    'users/extraList',
    wrapApiCall(
        async () => {
            const { mainUser, rows } = await post('list/subuser');

            return [mainUser, ...rows].map(mapUser);
        },
        { notify: false }
    )
);

export const extraUserAdd = createAsyncThunk(
    'users/addExtra',
    wrapApiCall<{
        name: string;
        email: string;
    }>(async ({ email, name }, { dispatch }) => {
        await post('reg/subuser', {
            user: email,
            name
        });

        dispatch(incUserLicence());
        await dispatch(extraUsersList({}));

        return {
            message: 'Пользователь успешно создан. На указанный e-mail выслано письмо с активацией аккаунта'
        };
    })
);

export const prolongExtraUser = createAsyncThunk(
    'users/prolongExtra',
    wrapApiCall<{
        userId: number;
    }>(async ({ userId }, { dispatch }) => {
        const resp = await post('add/subuser_time', {
            id: userId
        });
        dispatch(incUserLicence());
        await dispatch(extraUsersList({}));
        return resp;
    })
);

export const postPermissions = createAsyncThunk(
    'users/postPermissions',
    wrapApiCall<{
        users: Array<number>;
        folderId: number;
        userId: number;
        folders: Array<number>;
    }>(async (data) => {
        return await post('add/user_permissions', data);
    })
);

export const getPermissions = createAsyncThunk(
    'users/getPermissions',
    wrapApiCall<{
        users: Array<number>;
        folderId: number;
        byUsers: boolean;
    }>(
        (data) => {
            return post('show/user_permissions', data);
        },
        { notify: false }
    )
);

export const changeUserRole = createAsyncThunk(
    'users/changeUserRole',
    wrapApiCall<{
        userId: number;
        role: number;
    }>(async (data) => {
        return await post('change/user_role', data);
    })
);

export const changeUserName = createAsyncThunk(
    'users/changeUserName',
    wrapApiCall<{
        userId: number;
        name: string;
    }>(async (data) => {
        return await post('change/user_name', data);
    })
);

export const getSuperAdminUsers = createAsyncThunk(
    'users/getSuperAdminUsers',
    wrapApiCall(
        () => {
            return GET('get/superadmin_users');
        },
        { notify: false }
    )
);
