import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';

import type { AnswersRequest } from '../queries-api';

import { QuestionSimpleApi } from './common/simple';

export class SelectOneImageApi extends QuestionSimpleApi {
    public change(
        { answers }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(value);

        if (value) {
            answers.forEach((otherAnswer) => {
                if (response.answer !== otherAnswer) {
                    otherAnswer.response.setValue(false);
                }
            });
        }
    }

    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { value } }) => ({ answer: id, bool: (value as boolean) || false })),
            comment: commentText
        };
    }

    public validate({ answers, params: { required } }: ISurveyQuestionModel): boolean {
        if (required) {
            return answers.some((answer) => !!answer.response.value);
        }

        return true;
    }
}
