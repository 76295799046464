import type { ISurveyQuestionModel } from '@webapp/common/resources/mst-survey/question';
import type { ISurveyQuestionResponseModel } from '@webapp/common/resources/mst-survey/question_response';
import { AnswerType } from '@webapp/common/resources/survey';

import type { AnswersRequest } from '../queries-api';

import { ListApi } from './common/list';

export class FewOfListApi extends ListApi {
    public change(
        { answers }: ISurveyQuestionModel,
        response: ISurveyQuestionResponseModel,
        value: PrimitiveValue
    ): void {
        response.setValue(value);

        answers.forEach(({ response: { value }, setValid, valid }) => {
            if (value && !valid) {
                setValid(true);
            }
        });

        answers.forEach((otherAnswer) => {
            if ((response.answer.exception || otherAnswer.exception) && response.answer !== otherAnswer) {
                otherAnswer.response.setValue(false);

                if (otherAnswer.response.extra) {
                    otherAnswer.response.setExtra('');
                }
            }
        });
    }

    public query({ answers, commentText, id }: ISurveyQuestionModel): AnswersRequest {
        return {
            question: id,
            data: answers.map(({ id, response: { extra, value } }) => {
                value = !!value;
                return {
                    answer: id,
                    bool: value || false,
                    longtext: value && extra !== null ? String(extra) : undefined
                };
            }),
            comment: commentText
        };
    }

    public validate({ answers, params: { maxLength, minLength, required } }: ISurveyQuestionModel): boolean {
        if (!required) return true;

        const haveMaxLength = maxLength !== null;
        const haveMinLength = minLength !== null;
        let answeredCount = 0;
        let haveException = false;
        let valid = true;

        answers.forEach(({ exception, response, setValid, type }) => {
            if (!response) return;

            const value = !!response.value;
            const valid = type === AnswerType.SELF ? !!response.extra && value : value;

            if (valid) {
                ++answeredCount;
            }

            if (exception && value) {
                haveException = true;
            }

            setValid(valid);
        });

        if (haveException) return true;

        if (required) {
            valid = answeredCount > 0;
        }

        if (haveMinLength) {
            valid = valid && answeredCount >= minLength;
        }

        if (haveMaxLength) {
            valid = valid && answeredCount <= maxLength;
        }

        return valid;
    }
}
