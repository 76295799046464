import { Advs } from 'components/ui/advs';

import css from '../opportunities.css';

const templates = [
    {
        title: 'Индекс лояльности (NPS)',
        href: 'https://www.testograf.ru/ru/blog/nps-template'
    },
    {
        title: 'Индекс удовлетворённости CSI',
        href: 'https://www.testograf.ru/ru/blog/csi-survey-template'
    },
    {
        title: 'Оценка клиентского опыта (CSAT)',
        href: 'https://www.testograf.ru/ru/blog/csat-survey-template'
    },
    {
        title: 'Опрос CES - индекс клиентских усилий',
        href: 'https://www.testograf.ru/ru/blog/ces-template'
    },
    {
        title: 'Анкета тайного покупателя',
        href: 'https://www.testograf.ru/ru/blog/mystery-shopper-survey-template'
    },
    {
        title: 'Оценка лояльности клиента',
        href: 'https://www.testograf.ru/ru/blog/customer-loyalty-assessment-template'
    },
    {
        title: 'Опрос клиентов о качестве обслуживания',
        href: 'https://www.testograf.ru/ru/blog/customer-service-quality-survey-template'
    },
    {
        title: 'Анкета постоянного покупателя',
        href: 'https://www.testograf.ru/ru/blog/survey-regular-customer-template'
    },
    {
        title: 'Исследование удовлетворенности после покупки',
        href: 'https://www.testograf.ru/ru/blog/post-purchase-satisfaction-survey-template'
    },
    {
        title: 'Опрос об удовлетворенности продуктом',
        href: 'https://www.testograf.ru/ru/blog/product-satisfaction-survey-template'
    },
    {
        title: 'Узнаваемость бренда',
        href: 'https://www.testograf.ru/ru/blog/brand-recognition-template'
    },
    {
        title: 'Опрос по узнаваемости бренда №2',
        href: 'https://www.testograf.ru/ru/blog/brand-awareness-template'
    },
    {
        title: 'Опрос по оценке эффективности рекламной кампании',
        href: 'https://www.testograf.ru/ru/blog/advertising-campaign-survey-template'
    },
    {
        title: 'Опрос по выбору дизайна и характеристикам продукта',
        href: 'https://www.testograf.ru/ru/blog/design-choice-and-product-characteristics-template'
    },
    {
        title: 'Опрос после посещения магазина',
        href: 'https://www.testograf.ru/ru/blog/store-visit-survey-template'
    },
    {
        title: 'Опрос по оценке дизайна упаковки',
        href: 'https://www.testograf.ru/ru/blog/product-design-template'
    },
    {
        title: 'Оценка пользовательского опыта после обновления продукта',
        href: 'https://www.testograf.ru/ru/blog/after-product-update-survey-template'
    },
    {
        title: 'Оценка удобства использования интерфейса (System Usability Scale, SUS)',
        href: 'https://www.testograf.ru/ru/blog/sus-survey-template'
    },
    {
        title: 'Анкета обратной связи после обращения в поддержку',
        href: 'https://www.testograf.ru/ru/blog/opros-posle-obrashcheniya-v-podderzhku'
    },
    {
        title: 'Форма обратной связи для сайта',
        href: 'https://www.testograf.ru/ru/blog/feedback-form-template'
    }
];

export const Marketing: FC<{ title?: string }> = ({ title }) => {
    return (
        <div className={css.hr}>
            <h1>{title}</h1>
            <p>
                Маркетинговые опросы — это неотъемлемый инструмент для анализа целевой аудитории и принятия
                стратегических решений. С помощью Тестографа можно легко создавать, распространять и анализировать
                анкеты для любых маркетинговых исследований. Платформа предлагает мощный функционал для создания
                опросов, гибкие аналитические возможности и интеграции с различными сервисами, а также обеспечивает
                высокую стабильность работы и защиту данных. В этом материале мы подробно рассмотрим ключевые
                преимущества Тестографа и его функциональные особенности, которые делают этот сервис идеальным выбором
                для маркетологов.
            </p>
            <h2>Возможности маркетинговых опросов на Тестографе</h2>
            <h3>
                <strong>Готовые шаблоны и настраиваемые вопросы</strong>
            </h3>
            <p>
                Тестограф предлагает широкий выбор готовых шаблонов анкет для маркетинговых опросов, которые можно легко
                адаптировать под задачи вашего бизнеса. В конструкторе анкет доступно более 30 типов вопросов, включая
                закрытые, открытые, шкалы оценок и вопросы с множественным выбором. Вы также можете создавать свои
                уникальные вопросы с логическими связями, что позволит собирать точные и релевантные данные. Благодаря
                адаптивному дизайну, анкеты, созданные на Тестографе, корректно отображаются на любых устройствах.
            </p>
            <Advs items={templates} title='Шаблоны опросов' />
            <p className='alignCenter'>
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/auth/register'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Попробовать демо
                </a>{' '}
                &nbsp;
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/ru/templates'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Шаблоны опросов
                </a>
            </p>
            <h2>Расширенные аналитические возможности</h2>
            <h3>
                <strong>Фильтрация данных и сегментация аудитории</strong>
            </h3>
            <p>
                Тестограф предлагает продвинутые инструменты для анализа данных, собранных в ходе опросов. Система
                фильтров позволяет сегментировать результаты по разным критериям: демографическим данным, поведению
                респондентов и их ответам на конкретные вопросы. Это помогает маркетологам выделить важные
                закономерности и лучше понять целевую аудиторию.
            </p>
            <h3>
                <strong>Построение кросстаблиц</strong>
            </h3>
            <p>
                Кросстаблицы позволяют анализировать взаимосвязи между различными переменными в опросе. С помощью этой
                функции можно увидеть, как демографические показатели влияют на ответы респондентов, что помогает более
                глубоко изучить потребности и предпочтения аудитории. Это важный инструмент для построения эффективных
                маркетинговых стратегий.
            </p>
            <h3>
                <strong>Экспорт данных в SPSS, PDF, XLSX и CSV</strong>
            </h3>
            <p>
                Для профессиональной обработки данных Тестограф поддерживает экспорт результатов в различные форматы:{' '}
                <strong>SPSS</strong>, <strong>PDF</strong>, <strong>XLSX</strong> и <strong>CSV</strong>. Это позволяет
                гибко работать с результатами опросов и использовать сторонние аналитические инструменты для более
                глубокого анализа. Экспорт в SPSS подходит для тех, кто хочет проводить сложные статистические
                исследования, а форматы PDF и XLSX позволяют легко делиться отчетами внутри команды или с клиентами.
            </p>
            <h2>Распространение опросов</h2>
            <h3>
                <strong>Встроенный email-рассыльщик</strong>
            </h3>
            <p>
                Тестограф предлагает встроенный инструмент для рассылки анкет по email, который входит в каждый тарифный
                план. Это значит, что вам не нужно подключать сторонние сервисы для рассылки — достаточно
                воспользоваться встроенными возможностями платформы. Вы можете сегментировать аудиторию для
                таргетированных рассылок и отслеживать эффективность кампаний по отправке опросов.
            </p>
            <h3>
                <strong>Встраивание опросов на сайт</strong>
            </h3>
            <p>Платформа также предоставляет возможность встраивать опросы на ваш сайт с помощью различных методов:</p>
            <ul>
                <li>Виджеты. Опрос можно интегрировать в виде виджета на любую страницу сайта.</li>
                <li>Всплывающие окна. Этот метод позволяет привлечь внимание посетителей сайта к опросу.</li>
                <li>
                    Встраивание в тело страницы через iframe. Этот метод позволяет полноценно встроить анкету в любую
                    часть страницы без необходимости перехода на сторонний ресурс.
                </li>
            </ul>
            <h3>
                <strong>Панельные исследования и ривер-семплинг</strong>
            </h3>
            <p>
                Для компаний, которым требуется собрать данные не только от своей аудитории, но и от широкой выборки
                респондентов, на Тестографе доступен метод <strong>ривер-семплинга</strong> (от англ. river sampling).
                Этот подход подразумевает сбор данных у случайных пользователей Интернета, что делает его эффективным
                для проведения панельных исследований. С помощью ривер-семплинга можно быстро собрать данные от большой
                и разнообразной аудитории, что особенно полезно для масштабных маркетинговых исследований, где важно
                мнение широкого круга потребителей.
            </p>
            <h2>Интеграции и API</h2>
            <p>
                Тестограф поддерживает интеграции с различными платформами, а также предоставляет{' '}
                <strong>открытое API</strong>. Это позволяет компаниям и разработчикам настраивать любые интеграции
                самостоятельно, соединяя Тестограф с CRM-системами, аналитическими инструментами и другими
                корпоративными решениями. Открытое API позволяет гибко автоматизировать процессы сбора данных, их
                обработки и интеграции с внутренними бизнес-системами.
            </p>
            <h2>Демо-лицензия на Тестографе</h2>
            <p>
                Для новых пользователей платформа предоставляет <strong>демо-лицензию</strong>, которая позволяет
                бесплатно протестировать ключевые функции Тестографа. После регистрации вы можете создать один
                маркетинговый опрос и собрать до 10 ответов. Это отличный способ оценить возможности конструктора,
                протестировать готовые шаблоны и настроить логику вопросов без финансовых вложений.
            </p>
            <p className='alignCenter'>
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/auth/register'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Попробовать демо
                </a>{' '}
                &nbsp;
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/ru/templates'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Шаблоны опросов
                </a>
            </p>
            <h3>
                <strong>Преимущества демо-лицензии:</strong>
            </h3>
            <ul>
                <li>Возможность бесплатно протестировать интерфейс и функционал;</li>
                <li>Создание одного опроса и сбор до 10 ответов;</li>
                <li>Изучение аналитических функций, работы с интеграциями и рассылками.</li>
            </ul>
            <h2>Преимущества Тестографа перед другими сервисами</h2>
            <p>
                Тестограф выделяется на фоне конкурентов благодаря своей стабильности, широкому набору функций и
                высокому уровню безопасности данных. Рассмотрим ключевые преимущества платформы:
            </p>
            <h3>
                1. <strong>Стабильность работы</strong>
            </h3>
            <p>
                Тестограф гарантирует доступность сервиса на уровне 99,99% в год, что делает его одним из самых надежных
                инструментов для проведения опросов. Это особенно важно для компаний, которые активно используют опросы
                в своей деятельности и не могут допустить сбоев в работе платформы.
            </p>
            <h3>
                2. <strong>Функциональные возможности</strong>
            </h3>
            <p>
                Тестограф обладает одним из самых мощных конструкторов анкет на рынке. Платформа поддерживает более 30
                типов вопросов и широкие возможности по настройке логики прохождения опроса. Пользователи могут
                добавлять сложные условия, создавать персонализированные опросы и настраивать брендирование анкет,
                включая логотипы и фирменные цвета.
            </p>
            <h3>
                3. <strong>Безопасность данных</strong>
            </h3>
            <p>
                Безопасность информации — это приоритет для Тестографа. Процессы информационной безопасности выстроены в
                соответствии с международным циклом <strong>PDCA</strong>. Платформа внедряет организационные и
                технические меры защиты, включая шифрование данных, защиту от DDoS-атак и взломов, ежедневное резервное
                копирование и двухфакторную аутентификацию. Эти меры гарантируют, что данные респондентов находятся в
                безопасности на всех этапах обработки и хранения.
            </p>
            <h3>
                4. <strong>Оперативная поддержка</strong>
            </h3>
            <p>
                Тестограф предлагает оперативную техническую поддержку, которая доступна с 10:00 до 18:00 по МСК по
                email и телефону. Команда специалистов всегда готова помочь пользователям с любыми вопросами, будь то
                технические проблемы или консультации по настройке опросов.
            </p>
            <h3>
                5. <strong>Современный стек технологий</strong>
            </h3>
            <p>
                Тестограф работает на базе современных технологий, таких как <strong>React</strong>,{' '}
                <strong>PHP</strong> и <strong>Kubernetes</strong>. Это позволяет платформе быстро масштабироваться,
                эффективно справляться с высокими нагрузками и регулярно выпускать обновления функционала. Использование
                этого стека технологий обеспечивает стабильную работу платформы и высокую скорость ее работы, что делает
                Тестограф отличным выбором для бизнеса любого размера.
            </p>
            <h2>Заключение</h2>
            <p>
                Тестограф — это профессиональная платформа для создания опросов с широким набором функций. Благодаря
                готовым шаблонам, продвинутым аналитическим инструментам и встроенным сервисам рассылок, пользователи
                могут быстро и эффективно запустить любой маркетинговый опрос, получать ценные данные и улучшать свои
                продукты и услуги. Безопасность данных, стабильность работы и поддержка современных технологий делают
                Тестограф отличным выбором для компаний, стремящихся к качественным и точным исследованиям. Попробуйте{' '}
                <strong>Тестограф</strong> и откройте новые возможности для маркетинговых исследований!
            </p>
            <p>&nbsp;</p>
            <p className='alignCenter MsoNormal'>
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/auth/register'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Попробовать демо
                </a>{' '}
                &nbsp;
                <a
                    className='uiButton blue'
                    style={{ textDecoration: 'none' }}
                    href='https://www.testograf.ru/ru/templates'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Шаблоны опросов
                </a>
            </p>
            <h2>Видео-обучение по работе с Testograf:</h2>
            <div className='raw-html-embed'>
                <div style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '56.25%' }}>
                    <iframe
                        src='https://vk.com/video_ext.php?oid=-69308878&amp;id=456239081'
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                        allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
                        frameBorder='0'
                    ></iframe>
                </div>
            </div>
        </div>
    );
};
